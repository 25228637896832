/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS410M
 * 화면 설명: 전자서명(정보확인) - 계약관계자정보확인
 */
<template> 
  <ur-page-container class="msp" title="정보확인" :show-title="true" :topButton="true" @on-header-left-click="fn_HeaderBackBtnHandler" type="subpage">

    <!-- Content영역 -->
    <template #frame-header-fixed>
      <ur-box-container direction="column" alignV="start" class="con-area pt30 pb20 bd-b-Ty1"> 
        <span class="dsD fs18rem fwm mb10">보험료 출금계좌</span>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
          <mo-segment-wrapper solid primary v-model="authTypeSel[0].key" @input="fn_DpstrChangeClicked">
            <mo-segment-button v-for="(item, idx) in authTypes" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
          </mo-segment-wrapper>
        </ur-box-container>
      </ur-box-container>
    </template>
    <!-- content 영역 -->

    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap"> 
      <ur-box-container direction="column" alignH="center" class="pb82 bgcolor-1"><!-- //  class명 pb80 -> pb82 bgcolor-1 변경 및 이 영역 alignV="start"를 alignH="center" 2024.03.25 --> 
        <ur-box-container direction="column" alignV="start" class="card-list-wrap list-ty2"> 
        <!-- 피보험자 -->
        <template v-if="contrInsrdSameYn === 'N'">
          <msp-expand ref="mspExp3" expanded btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
            <template #title>

              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <mo-badge class="badge-sample-badge blue2" text="" shape="status">피보험자</mo-badge>
                  <!-- 고객 미선택 case -->
                  <template v-if="lv_insured.custNm === ''">
                    <div class="ml10">
                      <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                    </div> 
                  </template>
                  <!-- // 고객 미선택 case -->                  
                  <!-- 고객 선택 case -->
                  <template  v-else>
                    <div class="ml10">
                      <span class="name">{{lv_insured.custNm}}</span>                     
                    </div>
                  </template>
                  <!-- // 고객 선택 case -->  
                </div>
              </div>  
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_insured.custNm" readonly underline placeholder="성명" />
                </div>
                <div class="fexTy-half20">
                  <div class="w100 mt20">
                    <div class="label-title">연령</div>
                    <mo-text-field  class="form-input-name full" v-model="lv_insured.age + '세'" readonly underline placeholder="연령" />
                  </div>
                  <div class="w100 mt20">
                    <div class="label-title must">계약자와의 관계</div>
                    <msp-bottom-select class="ns-dropdown-sheet btm-error" underline bottom-title="계약자와의관계 선택" ref="pName" :rules="validateRule3" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="contrRltnScCdSel.key" :items="contrRltnScCd"/>
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field v-model="lv_insured.custRrnId1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                    <span>-</span>
                    <mo-text-field v-model="lv_insured.custRrnId2" readonly underline class="birth-input2 form-input-name w100"/>
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </template>
        <!-- 피보험자 끝 -->


        <!-- 예금주 -->
        <template v-if="authTypeSel[0].key === '1' && contrInsrdSameYn !== ''">
          <msp-expand ref="mspExp1" expanded btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> <!--기존 요건 :expanded="contrInsrdSameYn !== 'N'"-->
            <template #title>

              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <mo-badge class="badge-sample-badge green2" text="" shape="status">예금주</mo-badge>
                  <!-- 고객 미선택 case -->
                  <template v-if="lv_contr.custNm === ''">
                    <div class="ml10">
                      <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                    </div>
                  </template>
                  <!-- // 고객 미선택 case -->
                  <!-- 고객 선택 case -->
                  <template  v-else>
                    <div class="ml10">
                      <span class="name">{{lv_contr.custNm}}</span>
                    </div>
                  </template>
                  <!-- // 고객 선택 case -->  
                </div>
              </div>  
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_contr.custNm" readonly underline placeholder="성명" />
                </div>
                <div class="fexTy-half20">
                  <div class="w100 mt20">
                    <div class="label-title">연령</div>
                    <mo-text-field  class="form-input-name full" v-model="lv_contr.age + '세'" readonly underline placeholder="연령" />
                  </div>
                  <div class="w100 mt20">
                    <div class="label-title">계약자와의 관계</div>
                    <mo-text-field class="form-input-name full" value="계약자 본인" readonly underline placeholder="관계" />
                  </div>
                </div>
              </div>
            </template>
          </msp-expand>
        </template>

        <template v-if="authTypeSel[0].key === '2'">
          <msp-expand ref="mspExp2" expanded btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> <!--:expanded="contrInsrdSameYn !== 'N'"--> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <mo-badge class="badge-sample-badge green2" text="" shape="status">예금주</mo-badge>
                  <!-- 고객 미선택 case -->
                  <template v-if="hsmbDpstr.label === ''">
                    <div class="ml10">
                      <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                    </div>
                  </template>
                  <!-- // 고객 미선택 case -->
                  <!-- 고객 선택 case -->
                  <template  v-else>
                    <div class="ml10">
                      <span class="name">{{hsmbDpstr.label}}</span>
                    </div>
                  </template>
                  <!-- // 고객 선택 case -->  
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title must">성명</div>
                  <msp-bottom-select class="ns-dropdown-sheet btm-error" :itemValue="'key'" :itemText="'label'" underline bottom-title="성명" closeBtn scrolling placeholder="선택하세요" ref="yName" :rules="validateRule1" v-model="hsmbDpstr.key" :items="householdMembers" @selectedItem="fn_ChkHsmbDpstr"/>
                </div>
                <div class="w100 mt20">
                  <div class="label-title must">계약자와의 관계</div>
                  <msp-bottom-select class="ns-dropdown-sheet btm-error" underline bottom-title="계약자와의관계 선택" closeBtn scrolling placeholder="선택하세요" :rules="validateRule2" ref="yContr" :itemValue="'key'" :itemText="'label'" v-model="contrRltn.key" :items="contrFmrt"/>
                </div>
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                  <mo-button class="ns-btn-round rounded" @click.stop="fn_ShowMSPCM304P">세대조정</mo-button>
                  <mo-button class="ns-btn-round rounded" @click.stop="fn_MoveMSPCM001M('D')">고객등록동의</mo-button>
                </ur-box-container>
              </div>
            </template>
          </msp-expand>
        </template>
        <!-- 예금주 -->

        <!-- 친권1 -->
        <template v-if="showPrtr1Row">
          <msp-expand ref="mspExp5" expanded btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <mo-badge class="badge-sample-badge gray" text="" shape="status">친권자1</mo-badge>

                  <!-- 고객 미선택 case -->
                  <template v-if="lv_contr.custNm === ''">
                    <div class="ml10">
                      <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                    </div>
                  </template> 
                  <!-- // 고객 미선택 case -->
                  <!-- 고객 선택 case -->
                  <template  v-else>
                    <div class="ml10">
                      <span class="name">{{lv_contr.custNm}}</span>
                    </div>
                  </template>
                  <!-- // 고객 선택 case -->  
                </div>
              </div>  
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_contr.custNm" underline placeholder="성명" readonly/><!-- 친권인1 = 계약자 고정(ASR231000807_231107_PJO) -->
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_contr.age + '세'" underline placeholder="연령" readonly/>
                </div>
                <div class="w100 mt20">
                  <div class="fexTy3">
                    <span class="dsD label-title must">관계</span>
                    <div>
                      <mo-checkbox size="small mr10" v-model="singlePrtrCheckbox" @input="fn_SinglePrtrCheckboxOnClick">1인친권자</mo-checkbox>
                      <mo-icon icon="msp-question-gray" @click="fn_TooltipOpen"></mo-icon> 
                    </div>
                  </div>
                  <msp-bottom-select class="ns-dropdown-sheet btm-error" ref="cOneCrtn" :rules="validateRule11" underline bottom-title="계약자와의관계 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="prtr1ContrFmrtSel.key" :items="prtr1ContrFmrt" @input="fn_ChkPrtrContrFmrt('1')"/>
                </div>
                <ur-box-container v-if="authTypeSel[0].key === '2'" alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                  <mo-button class="ns-btn-round rounded" @click="fn_ShowMSPCM304P">세대조정</mo-button>
                </ur-box-container>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field v-model="lv_contr.custRrnId1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                    <span>-</span>
                    <mo-text-field v-model="lv_contr.custRrnId2" readonly underline class="birth-input2 form-input-name w100"/>
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" ref="cOnePhone" :rules="validateRule12" v-model="lv_contr.custCelno" readonly underline placeholder="휴대폰번호" />
                </div>
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                  <!-- <mo-button class="ns-btn-round rounded" >세대조정</mo-button> -->
                  <mo-button class="ns-btn-round rounded" @click="fn_ShowMSPCM301D(lv_contr.chnlCustId)">수정</mo-button>
                </ur-box-container>
                <ul class="terms-list-area crTy-bk7 fs14rem mt10">
                  <li>계약자가 친권인이 아닌 경우 전자서명 불가합니다. 모바일청약 혹은 지류청약 진행 바랍니다.</li>
                  <li>위 연락처는 안내메세지 발송 용도로 사용됩니다. 변경이 필요하신 경우 수정 바랍니다.</li>
                </ul>
              </div>
            </template>
          </msp-expand>
        </template>
        <!-- 친권1 끝 -->

        <!-- 친권2 -->
        <template v-if="showPrtr2Row">
          <msp-expand ref="mspExp6" expanded btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <mo-badge class="badge-sample-badge gray" text="" shape="status">친권자2</mo-badge>
                  <!-- 고객 미선택 case -->
                  <template v-if="lv_Prtr2Sel.label === ''">
                    <div class="ml10">
                      <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                    </div> 
                  </template>
                  <!-- // 고객 미선택 case -->
                  <!-- 고객 선택 case -->
                  <template  v-else>
                    <div class="ml10">
                      <span class="name">{{lv_Prtr2Sel.label}}</span>
                    </div>
                  </template>
                  <!-- // 고객 선택 case -->  
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title must">성명</div>
                  <msp-bottom-select class="ns-dropdown-sheet btm-error" ref="cTwoNm" :rules="validateRule15" underline bottom-title="성명" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="lv_Prtr2Sel.key" :items="householdMembers" @selectedItem="fn_ChkPrtr"/>
                </div>
                <div class="w100 mt20">
                  <div class="fexTy3">
                    <span class="dsD label-title must">관계</span>
                  </div>
                  <msp-bottom-select class="ns-dropdown-sheet btm-error" ref="cTwoCrtn" :rules="validateRule13" underline bottom-title="계약자와의관계 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="prtr2ContrFmrtSel.key" :items="prtr2ContrFmrt" @input="fn_ChkPrtrContrFmrt('2')"/>
                </div>
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                  <mo-button class="ns-btn-round rounded" @click="fn_ShowMSPCM304P">세대조정</mo-button>
                </ur-box-container>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field v-model="lv_Prtr2Sel.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                    <span>-</span>
                    <mo-text-field v-model="lv_Prtr2Sel.knbEncr2" readonly underline class="birth-input2 form-input-name w100"/>
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" ref="cTwoPhone" :rules="validateRule14" v-model="lv_Prtr2Sel.celno" readonly underline placeholder="휴대폰번호" />
                </div>
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                  <!-- <mo-button class="ns-btn-round rounded" >세대조정</mo-button> -->
                  <mo-button class="ns-btn-round rounded" v-if="lv_Prtr2Sel.chnlCustId !== ''" @click="fn_ShowMSPCM301D(lv_Prtr2Sel.chnlCustId)">수정</mo-button>
                </ur-box-container>
              </div>
            </template>
          </msp-expand>
        </template>
        <!-- 친권2 끝 -->

        <!-- 수익자 (계약자) -->
        <template v-if="isBnfrInfo31 || isBnfrInfo32 || isBnfrInfo33 || isBnfrInfo39">
          <msp-expand expanded btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <mo-badge class="badge-sample-badge purple2" text="" shape="status">수익자</mo-badge>
                  <!-- 고객 미선택 case -->
                  <template v-if="bnfrInfo1_custNm === ''">
                    <div class="ml10">
                      <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                    </div> 
                  </template>
                  <template  v-else>
                  <!-- // 고객 미선택 case -->
                  <!-- 고객 선택 case -->
                    <div class="ml10">
                      <span class="name">{{ bnfrInfo1_custNm }}</span>
                      <mo-badge class="badge-sample-badge sm ml10 lightgreen" text="" shape="status">계약자</mo-badge>
                    </div>
                  </template>
                  <!-- // 고객 선택 case -->  
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <mo-tab-box :defaultIdx="isBnfrInfo31 ? '1' : (isBnfrInfo32 ? '2' : (isBnfrInfo33 ? '3' : '4'))" class="ns-move-tab-box tween w100">
                <mo-tab-label idx="1" v-if="isBnfrInfo31">만기</mo-tab-label>
                <mo-tab-label idx="2" v-if="isBnfrInfo32">분할</mo-tab-label>
                <mo-tab-label idx="3" v-if="isBnfrInfo33">연금</mo-tab-label>
                <mo-tab-label idx="4" v-if="isBnfrInfo39">연금후</mo-tab-label>
                <mo-tab-content idx="1">
                  <!-- tab1 -->
                  <div class="con-area pt30 pb30">
                    <div class="w100">
                      <div class="label-title">성명</div>
                      <mo-text-field v-model="bnfrInfo31.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">연령</div>
                      <mo-text-field v-model="bnfrInfo31.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">주민등록번호</div>
                      <div class="verify-birth">
                        <mo-text-field v-model="bnfrInfo31.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                        <span>-</span>
                        <mo-text-field v-model="bnfrInfo31.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="3  ●  ●  ●  ●  ●" />
                      </div>
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">지급률(%)</div>
                      <mo-text-field v-model="bnfrInfo31.shareVl" class="form-input-name full" readonly underline placeholder="관계" />
                    </div>
                  </div>
                </mo-tab-content>
                <mo-tab-content idx="2">
                  <!-- tab2 -->
                  <div class="con-area pt30 pb30">
                    <div class="w100">
                      <div class="label-title">성명</div>
                      <mo-text-field v-model="bnfrInfo32.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">연령</div>
                      <mo-text-field v-model="bnfrInfo32.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">주민등록번호</div>
                      <div class="verify-birth mt20">
                        <mo-text-field v-model="bnfrInfo32.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                        <span>-</span>
                        <mo-text-field v-model="bnfrInfo32.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="3  ●  ●  ●  ●  ●" />
                      </div>
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">지급률(%)</div>
                      <mo-text-field v-model="bnfrInfo32.shareVl" class="form-input-name full" readonly underline placeholder="관계" />
                    </div>
                  </div>
                </mo-tab-content>
                <mo-tab-content idx="3">
                  <!-- tab3 -->
                  <div class="con-area pt30 pb30">
                    <div class="w100">
                      <div class="label-title">성명</div>
                      <mo-text-field v-model="bnfrInfo33.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">연령</div>
                      <mo-text-field v-model="bnfrInfo33.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">주민등록번호</div>
                      <div class="verify-birth mt20">
                        <mo-text-field v-model="bnfrInfo33.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                        <span>-</span>
                        <mo-text-field v-model="bnfrInfo33.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="3  ●  ●  ●  ●  ●" />
                      </div>
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">지급률(%)</div>
                      <mo-text-field v-model="bnfrInfo33.shareVl" class="form-input-name full" readonly underline placeholder="관계" />
                    </div>
                  </div>
                </mo-tab-content>
                <mo-tab-content idx="4">
                  <!-- tab4 -->
                  <div class="con-area pt30 pb30">
                    <div class="w100">
                      <div class="label-title">성명</div>
                      <mo-text-field v-model="bnfrInfo39.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">연령</div>
                      <mo-text-field v-model="bnfrInfo39.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">주민등록번호</div>
                      <div class="verify-birth mt20">
                        <mo-text-field v-model="bnfrInfo39.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                        <span>-</span>
                        <mo-text-field v-model="bnfrInfo39.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="3  ●  ●  ●  ●  ●" />
                      </div>
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">지급률(%)</div>
                      <mo-text-field v-model="bnfrInfo39.shareVl" class="form-input-name full" readonly underline placeholder="관계" />
                    </div>
                  </div>
                </mo-tab-content>      
              </mo-tab-box>        
            </template>
          </msp-expand>
        </template>

        <!-- 수익자 (주피) -->
        <template v-if="isBnfrInfo34 || isBnfrInfo35">
          <msp-expand expanded btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1" > 
            <template #title>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <mo-badge class="badge-sample-badge purple2" text="" shape="status">수익자</mo-badge>
                  <!-- 고객 미선택 case -->
                  <template v-if="bnfrInfo2_custNm === ''">
                    <div class="ml10">
                      <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                    </div> 
                  </template>
                  <!-- // 고객 미선택 case -->
                  <!-- 고객 선택 case -->
                  <template  v-else>
                    <div class="ml10">
                      <span class="name">{{ bnfrInfo2_custNm }}</span>
                      <mo-badge class="badge-sample-badge sm ml10 lightblue" text="" shape="status">주피</mo-badge>
                    </div>
                  </template>
                  <!-- // 고객 선택 case -->  
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <mo-tab-box :defaultIdx="isBnfrInfo34 ? '1' : '2'" class="ns-move-tab-box tween w100">
                <mo-tab-label idx="1" v-if="isBnfrInfo34">상해</mo-tab-label>
                <mo-tab-label idx="2" v-if="isBnfrInfo35">사망</mo-tab-label>
                <mo-tab-content idx="1">
                  <!-- tab1 -->
                  <div class="con-area pt30 pb30">
                    <div class="w100">
                      <div class="label-title">성명</div>
                      <mo-text-field v-model="bnfrInfo34.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">연령</div>
                      <mo-text-field v-model="bnfrInfo34.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">주민등록번호</div>
                      <div class="verify-birth">
                        <mo-text-field v-model="bnfrInfo34.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                        <span>-</span>
                        <mo-text-field v-model="bnfrInfo34.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="3  ●  ●  ●  ●  ●" />
                      </div>
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">지급률(%)</div>
                      <mo-text-field v-model="bnfrInfo34.shareVl" class="form-input-name full" readonly underline placeholder="관계" />
                    </div>
                  </div>
                </mo-tab-content>
                <mo-tab-content idx="2">
                  <!-- tab2 -->
                  <div class="con-area pt30 pb30">
                    <div class="text-blue-box bgcolor-7 pt16 pb16 mb10" v-for="(bnfrInfo, index) in bnfrInfo35" :key="index">
                      <div class="fexTy5">
                        <span class="fs18rem ml0 flex-1 fwm">{{bnfrInfo.custNm}}</span>
                        <mo-button class="ns-btn-round h28 blue-line" v-if="bnfrInfo.bnfrSno === 1 && isAmlDthBnfr01" @click="fn_ShowMSPCM301D(amlDthBnfr01_chnlCustId)">수정</mo-button>
                      </div>
                      <span class="dsD ml0 mt10"><span class="fs14rem ml0 crTy-bk7 min100">주민등록번호</span>{{bnfrInfo.knbEncr1}}-{{bnfrInfo.knbEncr2}}</span>
                      <span class="dsD ml0 mt4"><span class="fs14rem ml0 crTy-bk7 min100">연령</span>{{bnfrInfo.age}}세</span>
                      <span class="dsD ml0 mt4" v-if="bnfrInfo.cusDtlTypCd === '11'"><span class="fs14rem ml0 crTy-bk7 min100">내외국인구분</span>내국인</span>
                      <span class="dsD ml0 mt4" v-if="bnfrInfo.cusDtlTypCd === '12'"><span class="fs14rem ml0 crTy-bk7 min100">내외국인구분</span>외국인</span>
                      <span class="dsD ml0 mt4"><span class="fs14rem ml0 crTy-bk7 min100">지급률</span>{{bnfrInfo.shareVl}}%</span>
                      <ul class="terms-list-area crTy-orange2 fs14rem mt10" v-if="bnfrInfo.bnfrSno === 1 && isAmlDthBnfr01">
                        <li>AML 대상입니다. 고객정보를 확인해 주세요.</li>
                      </ul>
                    </div>

                  </div>
                </mo-tab-content>
              </mo-tab-box>        
            </template>
          </msp-expand>
        </template>

        <!-- 계약자 -->
        <msp-expand ref="mspExp4" expanded btn-area-first title-first expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
          <template #title>
            <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <mo-badge class="badge-sample-badge green" text="" shape="status">계약자</mo-badge>
                  <!-- 고객 미선택 case -->
                  <template v-if="lv_contr.custNm === ''">
                    <div class="ml10">
                      <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                    </div> 
                  </template>
                  <!-- // 고객 미선택 case -->
                  <!-- 고객 선택 case -->
                  <template  v-else>
                    <div class="ml10">
                      <span class="name">{{lv_contr.custNm}}</span>
                      <mo-badge class="badge-sample-badge sm ml10 lightgreen" text="" shape="status">계약자</mo-badge>
                    </div>
                  </template>
                  <!-- // 고객 선택 case -->  
                </div>
              </div>
          </template>
          <template #btn>
            <mo-button class="link-arrow">
              <mo-icon icon="expand_menu" class="expand__icon" />                  
              <span class="open">접기</span>
              <span class="close">열기</span>
            </mo-button>
          </template>
          <template #content>
            <div class="con-area pt10 pb30">
              <div class="w100">
                <div class="label-title">성명</div>
                <mo-text-field  class="form-input-name full" v-model="lv_contr.custNm" readonly underline placeholder="성명" />
              </div>
              <div class="w100 mt20">
                <div class="label-title">연령</div>
                <mo-text-field  class="form-input-name full" v-model="lv_contr.age + '세'" readonly underline placeholder="연령" />
              </div>
              <div class="w100 mt20">
                <div class="label-title">주민등록번호</div>
                <div class="verify-birth">
                  <mo-text-field v-model="lv_contr.custRrnId1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                  <span>-</span>
                  <mo-text-field v-model="lv_contr.custRrnId2" readonly underline class="birth-input2 form-input-name w100"/>
                </div>
              </div>
              <div class="w100 mt20">
                <div class="label-title">지정대리청구인</div>
                <msp-bottom-select class="ns-dropdown-sheet btm-error" ref="apcPrsonAplScSel" :rules="validateRule4" :itemValue="'key'" :itemText="'label'" underline bottom-title="지정대리청구인 선택" closeBtn scrolling placeholder="선택하세요" v-model="apcPrsonAplScSel.key" :items="apcPrsonAplScList" :disabled="!isAsgnProxyClamPsb" @selectedItem="fn_ApcPrsonAplScSel_OnClick"/>
                <ul class="terms-list-area crTy-bk7 fs14rem mt10" v-if="isApcAsgnView"> <!-- ASR240200999_간편지대청 도입(김문준/240423) -->
                  <li>{{apcInfoMsg}}</li>
                  <li v-if="zzInsrTypMgId === 'N01'">{{apcInfoMsg2}}</li> <!-- ASR240200999_간편지대청 도입(김문준/240423) -->
                </ul>
              </div>
              
              <!-- 지정대리청구인 -->
              <!-- 1인 -->
              <div class="w100 mt20" v-if="isApcAsgnView">
                <div class="label-title">{{apcLblTxt1}} (대표)</div>
                <msp-bottom-select class="ns-dropdown-sheet btm-error" ref="jOneDa" :rules="validateRule5" underline bottom-title="지정대리청구인 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="apcInfo.key" :items="householdMembers_apc" @selectedItem="fn_SetApc1"/>
                <div class="w100 mt50" v-if="isApcAsgnView_default1">
                  <div class="label-title mt20">피보험자와의 관계</div>
                  <msp-bottom-select class="ns-dropdown-sheet btm-error" ref="jOneCrtn" :rules="validateRule6" underline bottom-title="피보험자와의 관계 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="apclamInfoSel.key" :items="apcContrRltnScCdList"/>
                  
                  <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                    <mo-button class="ns-btn-round rounded" @click="fn_ShowMSPCM304P">세대조정</mo-button>
                    <mo-button class="ns-btn-round rounded" @click="fn_MoveMSPCM001M('A1')">고객등록동의</mo-button>
                  </ur-box-container>
                </div>
                <!-- ASR240200999_간편지대청 도입(김문준/240423)/start-->
                <div class="w100 mt50" v-if="isApcAsgnView_simple1">
                  <div class="w100 mt50">
                    <div class="contain-title">지정대리청구인1 성명</div>
                      <mo-text-field  ref="custNmRef1" class="form-input-name full" maxlength="10" :rules="validateRuleName" underline placeholder="입력하세요!" v-model="apcCustNm_simple1" @blur="fn_CheckCustNm('1')"/>
                    </div>
                  <div class="contain-title mt50">피보험자와의 관계</div>
                  <msp-bottom-select class="ns-dropdown-sheet btm-error" ref="jDoneCrtn" :rules="validateRule7" underline bottom-title="피보험자와의 관계 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="apclamInfoSel_simple1.key" :items="apcContrRltnScCdList_simple"/>
                </div>
                <!-- ASR240200999_간편지대청 도입(김문준/240423)/end-->  
              </div>
              <!-- 1인 끝 -->

              <!-- 2인 -->
              <div class="w100 mt20" v-if="isApcAsgnView2">
                <div class="label-title">{{apcLblTxt2}}</div>
                <msp-bottom-select class="ns-dropdown-sheet btm-error" ref="jTwoDa" :rules="validateRule8" underline bottom-title="지정대리청구인 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="apcInfo2.key" :items="householdMembers_apc" @selectedItem="fn_SetApc2"/>
                <div class="w100 mt50" v-if="isApcAsgnView_default2">
                  <div class="label-title mt20">피보험자와의 관계</div>
                  <msp-bottom-select class="ns-dropdown-sheet btm-error" ref="jTwoCrtn" :rules="validateRule9" underline bottom-title="피보험자와의 관계 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="apclamInfoSel2.key" :items="apcContrRltnScCdList"/>
                  
                  <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                    <mo-button class="ns-btn-round rounded" @click="fn_ShowMSPCM304P">세대조정</mo-button>
                    <mo-button class="ns-btn-round rounded" @click="fn_MoveMSPCM001M('A2')">고객등록동의</mo-button>
                  </ur-box-container>
                </div>
                <!-- ASR240200999_간편지대청 도입(김문준/240423)/start-->
                <div class="w100 mt50" v-if="isApcAsgnView_simple2">
                  <div class="w100 mt50">
                    <div class="contain-title">지정대리청구인2 성명</div>
                    <mo-text-field ref="custNmRef2" class="form-input-name full" maxlength="10" :rules="validateRuleName2" underline placeholder="입력하세요!" v-model="apcCustNm_simple2" @blur="fn_CheckCustNm('2')"/>
                  </div>
                  <div class="contain-title mt50">피보험자와의 관계</div>
                  <msp-bottom-select class="ns-dropdown-sheet btm-error" ref="jDtwoCrtn" :rules="validateRule10" underline bottom-title="피보험자와의 관계 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="apclamInfoSel_simple2.key" :items="apcContrRltnScCdList_simple"/>
                </div>
                <!-- ASR240200999_간편지대청 도입(김문준/240423)/end-->
              </div>
              <!-- 2인 끝 -->
            </div>
          </template>
        </msp-expand>
        <!-- 계약자 끝-->

        <ur-box-container direction="column" align-v="start" class="ns-agreeArea2">
          <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MoveMSPPS420M">다음</mo-button>
            </div>
          </ur-box-container>
        </ur-box-container>

       </ur-box-container> 

      </ur-box-container>
    </ur-box-container>


    <!-- Popup001 -->
    <mo-bottom-sheet ref="nsbottomsheet" :close-btn="true" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        공동친권, 1인친권자
      </template>
       
      <div class="content-area">
        <div>
          <span class="dsD fs18rem fwb">공동친권</span>
          <span class="dsD fs17remb mt10">다른 법정대리인과의 합의 하에 공동으로 친권을 행사함</span>
        </div>
        <div class="mt30">
          <span class="dsD fs18rem fwb">1인친권자</span>
          <span class="dsD fs17remb mt10">계약자 또는 피보험자의 친권을 단독으로 행사함</span>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_TooltipClose" name="닫기">닫기</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 지정대리청구인  미지정/지정불가 선택시 // ASR241100645_간편 지정대리청구인 넛지 (241203_PJO) -->
    <mo-bottom-sheet ref="refApcAlertFlag" class="ns-bottom-sheet msp-bottomSt-alert">
      <div class="customer-info-txt txt-center">
        <p class="ment" v-html="asgmMsg"></p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ApcAlert1" name="아니오">미지정/지정불가</mo-button>
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ApcAlert2" name="예">지정</mo-button>
        </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSElstUtil from '@/ui/ps/comm/PSElstUtil'
import PSAmlUtil from '@/ui/ps/comm/PSAmlUtil'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import MSPCM300P from '@/ui/cm/MSPCM300P'
import MSPCM304P from '@/ui/cm/MSPCM304P'

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS410M', 
  screenId: 'MSPPS410M',

  components: {
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {       
      show1 : true, //피보험자
      show2 : true, //예금주
      show3 : true, //친권1
      show4 : true, //친권2
      show5 : true, //계약자
      validateRule1: [
        v=> !!v || '필수입니다. 성명을 선택하세요.'        
      ],
      validateRule2: [
        v=> !!v || '필수입니다. 계약자와의 관계를 선택하세요.'        
      ],
      validateRule3: [
        v=> !(this.contrInsrdSameYn === 'N' && this.contrRltnScCdSel.key === '' ) || '필수입니다. 피보험자의 계약자와의 관계가 선택되지 않았습니다.'        
      ],
      validateRule4: [
        v => !(this.isAsgnProxyClamPsb && this.$bizUtil.isEmpty(this.apcPrsonAplScSel.key)) ||  '필수입니다. 지정대리청구인을 선택해 주세요.' ,
        v => !(this.isAsgnProxyClamPsb && !this.$refs['mspExp4'].isExpand && this.simpAsgnClamAgntCd01 == 'B' && ( this.$bizUtil.isEmpty(this.apcCustNm_simple1) || this.$bizUtil.isEmpty(this.apclamInfoSel_simple1.key))) ||  '필수입니다. 지정대리청구인 정보를 입력하세요.'
      ],
      validateRule5: [
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView && this.simpAsgnClamAgntCd01 === '' ) ||  '필수입니다. 지정대리청구인이 선택되지 않았습니다.' ,
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView && this.simpAsgnClamAgntCd01 == 'A' && this.$bizUtil.isEmpty(this.apcInfo.key) ) ||  '필수입니다. 지정대리청구인이 선택되지 않았습니다.'
      ],
      validateRule6: [
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView && this.simpAsgnClamAgntCd01 == 'A' && this.$bizUtil.isEmpty( this.apclamInfoSel.key) ) ||  '필수입니다. 지정대리청구인 피보험자와의 관계가 선택되지 않았습니다.'
      ],
      validateRule7: [
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView && this.simpAsgnClamAgntCd01 == 'B' && this.$bizUtil.isEmpty(this.apclamInfoSel_simple1.key) ) ||  '필수입니다. 지정대리청구인1 피보험자와의 관계가 선택되지 않았습니다.'         
      ],
      validateRule8: [
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView2 && this.simpAsgnClamAgntCd02 === '' ) ||  '필수입니다. 지정대리청구인(2)이 선택되지 않았습니다.' ,
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView2 && this.simpAsgnClamAgntCd02 == 'A' ) ||  '필수입니다. 지정대리청구인(2)이 선택되지 않았습니다.'
      ],
      validateRule9: [
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView2 && this.simpAsgnClamAgntCd02 == 'A' && this.$bizUtil.isEmpty(this.apclamInfoSel2.key) ) ||  '필수입니다. 지정대리청구인(2) 피보험자와의 관계가 선택되지 않았습니다.' 
      ],
      validateRule10: [
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView2 && this.simpAsgnClamAgntCd02 == 'B' && this.$bizUtil.isEmpty(this.apclamInfoSel_simple2.key) ) ||  '필수입니다. 지정대리청구인 피보험자와의 관계(2)가 선택되지 않았습니다.' 
      ],
      validateRule11: [
        v => !(this.showPrtr1Row === true && (this.showPrtr2Row === true || (this.showPrtr2Row === false && this.singlePrtrCheckbox === true) ) && this.prtr1ContrFmrtSel.key === '' ) ||  '필수입니다. 친권1 관계가 선택되지 않았습니다.'
      ],
      validateRule12: [
        v => !(this.showPrtr1Row === true && (this.showPrtr2Row === true || (this.showPrtr2Row === false && this.singlePrtrCheckbox === true) ) && this.$bizUtil.isEmpty(this.lv_Prtr1Sel.celno) ) ||  '필수입니다. 친권자1의 휴대폰 번호가 없습니다.'
      ],
      validateRule13: [
        v => !(this.showPrtr1Row === true && (this.showPrtr2Row === true || (this.showPrtr2Row === false && this.singlePrtrCheckbox === true) ) && !this.singlePrtrCheckbox && this.prtr2ContrFmrtSel.key === '' ) ||  '필수입니다. 친권2 관계가 선택되지 않았습니다.',
        v => !(this.showPrtr1Row === true && (this.showPrtr2Row === true || (this.showPrtr2Row === false && this.singlePrtrCheckbox === true) ) && !this.singlePrtrCheckbox && this.prtr1ContrFmrtSel.key === this.prtr2ContrFmrtSel.key ) ||  '필수입니다. 친권1과 동일한 관계가 지정되었습니다.'
      ],
      validateRule14: [
        v => !(this.showPrtr1Row === true && (this.showPrtr2Row === true || (this.showPrtr2Row === false && this.singlePrtrCheckbox === true) ) && this.singlePrtrCheckbox === false ) ||  '필수입니다. 친권자2의 휴대폰 번호가 없습니다.'
      ],
      validateRule15: [ 
        v=> !(this.showPrtr1Row === true && (this.showPrtr2Row === true || (this.showPrtr2Row === false && this.singlePrtrCheckbox === true) ) && this.lv_Prtr2Sel.key === '' )  || '필수입니다. 친권2 성명을 선택하세요.'        
      ], 
      bnfrInfo31 : {}, //만기수익자
      bnfrInfo32 : {}, //분할수익자
      bnfrInfo33 : {}, //연금수익자
      bnfrInfo34 : {}, //상해수익자
      bnfrInfo35 : [], //사망수익자 (최대4명)
      bnfrInfo39 : {}, //연금후수익자
      isAmlDthBnfr01 : false, //사망수익자 AML대상여부
      amlDthBnfr01_chnlCustId : '', // 사망수익자1의 채널고객ID
      isBnfrInfo31 : false,
      isBnfrInfo32 : false,
      isBnfrInfo33 : false,
      isBnfrInfo34 : false,
      isBnfrInfo35 : false,
      isBnfrInfo39 : false,
      bnfrInfo1_custNm : '',  //수익자명 (계약자)
      bnfrInfo2_custNm : '',  //수익자명 (주피)

      componentKey: 0,
      lv_InputData: {}, // 받아온 데이터
      jsonString: '',
      lv_contr: { // 계약자
        custNm: '',
        age: '',
        custRrnId1: '',
        custRrnId2: '',
        chnlCustId: '',
        chnlCstgrId: '',
        custCelno: '' // 휴대폰번호 (22.02.08/PJO)
      },
      lv_insured: { // 피보험자
        custNm: '',
        age: '',
        custRrnId1: '',
        custRrnId2: '',
        chnlCustId: '',
        chnlCstgrId: ''
      },
      lv_dpstr: { // 예금주
        custNm: '', // 예금주 이름
        chnlCustId: '', // 예금주 채널고객ID
        contrFmrt: '', // 계약자/예금주관계ID
        contrDpstrSameYn: '', // 계약자예금주동일여부
        insrdDpstrSameYn: '', // 피보험자예금주동일여부
        dpstrIdpnYn: '', // 예금주독립여부
        knbEncr: ''
      },
      contrInsrdSameYn: '', // 계피상이
      authTypes: [{
        key: '1',
        label: '예금주 / 계약자 동일'
      },
      {
        key: '2',
        label: '예금주 / 계약자 상이'
      }],
      authTypeSel: [{
        key: '1',
        label: '예금주 / 계약자 동일'
      }],
      // 세대원 목록 (계약자 제외)
      householdMembers: [
        {
          key: '', // 순번
          label: '', // 이름 4글자
          name: '', // 이름
          chnlCustId: '', // 채널고객ID
          knbEncr: '', // 주민등록번호 암호화 : YYMMDD1******
          knbEncr1: '', // 주민번호 앞자리
          knbEncr2: '', // 주민번호 뒷자리
          celno: '', // 연락처
          isMcnstYN: 'N', // 필수컨설팅동의여부
          style: true // 스타일 , 필수컨설팅동의:N -> true
        }
      ],
      // 세대원 예금주
      hsmbDpstr: {
        key: '',
        label: '선택하세요',
        style: true,
        name: '',
        chnlCustId: ''
      },
      // 계약자 가족관계 : 예금주관계코드/ZA_DPSTR_RLTN_CD  -> 차량소유구분코드/ZA_CAR_OWN_SC_CD
      contrFmrt: [
        {
          key: '02',
          label: '배우자'
        },
        {
          key: '04',
          label: '부모'
        },
        {
          key: '03',
          label: '자녀'
        }
      ],
      // 계약자관계
      contrRltn: {
        key: '',
        label: ''
      },
      isDpstrChange: false, // 동일/상이 변경
      standardAge: 19, // 미성년자 기준 나이

      isAsgnProxyClamPsb: false, // 지정대리청구인 지정가능여부
      isApcAsgnView: false, // 지정대리청구인 표시여부
      //showAsgnProxyClamConfirm: false, // 지정대리청구인 필수컨설팅
      // 지정대리청구인 정보 apcPrsonAplSc
      apclamInfoSel: { // 선택한 지정대리청구인(피보험자와의) 관계
        key: '',
        label: ''
      },
      // 지정대리청구인 정보
      apcInfo: {
        key: '',
        label: '',
        style: true,
        name: '',
        chnlCustId: ''
      },
      apcContrRltnScCdList: [ // 지정대리청구인-계약자와의관계
        {key: '04', label: '부모'},
        {key: '02', label: '배우자'},
        {key: '03', label: '자녀'},
        // 05:기타가족
        // 06:친족
        {key: '61', label: '조부모'},
        {key: '62', label: '손자'},
        {key: '63', label: '형제'},
        {key: '64', label: '삼촌'},
        {key: '65', label: '외삼촌'},
        {key: '66', label: '고모'},
        {key: '67', label: '이모'},
        {key: '68', label: '조카'}
        // 통합코드:계약자관계코드
        // FC선택가능한 관계 N:1 통합코드 로 매핑함
      ],
      // 지정대리청구_보완 (2021.05.31_PJO)_START
      isApcAsgnView2: false, // 지정대리청구인 표시여부(2인)
      zzInsrTypMgId: '', // 보험유형관리코드RD
      apcInfoMsg: '', // 지정대리청구인 안내 메세지
      // apcPrsonAplScSel: {key: 'M1', label: '미지정(계약자요청)'}, // 기본값 ( 선택시.여기세팅 )
      apcPrsonAplScSel: {key: '', label: ''}, // 기본값 ( 선택시.여기세팅 )
      // apcPrsonAplScCd: 'M1', // 지정대리청구인신청구분코드
      apcPrsonAplScList: [ // 지정대리청구인신청구분리스트 01(1인지정), 02(2인지정), M1(미지정), M2(지정불가)
        {key: '01', label: '1인지정'},
        {key: '02', label: '2인지정'},
        {key: 'M1', label: '미지정(계약자요청)'},
        {key: 'M2', label: '지정불가(약관상 자격 갖춘 자 없음)'}
      ],
      apcLblTxt1: '지정대리청구인', // 지정대리청구인 라벨 표기 (1인 : 지정대리청구인, 2인 : 지정대리청구인1(대표)
      apcLblTxt2: '지정대리청구인2', // 지정대리청구인 라벨 표기2 (2인일경우만 표기됨)
      // 지정대리청구인 정보 2
      apcInfo2: {
        key: '',
        label: '',
        style: true,
        name: '',
        chnlCustId: ''
      },
      apclamInfoSel2: { // 선택한 지정대리청구인 관계 2
        key: '',
        label: ''
      },
      // 지정대리청구_보완 (2021.05.31_PJO)_END
      // [전자서명 ] 계약관계자 기재방식 변경 및 로직수정_(ASR210600884_2021.07.20_PJO)_START
      showPrtr1Row: false, // 친권1 표시여부
      showPrtr2Row: false, // 친권2 표시여부
      // disPrtr2Row: false, // 친권2 비활성여부
      // 친권1
      lv_Prtr1Sel: { // 선택한 친권1
        custNm: '', // 이름
        chnlCustId: '', // 채널고객ID
        contrFmrt: '', // 계약자/예금주관계ID
        custCelno: '',
        custRrnId: '',
        contrDpstrSameYn: '', // 계약자예금주동일여부
        insrdDpstrSameYn: '', // 피보험자예금주동일여부
        // dpstrIdpnYn: '', // 예금주독립여부
        knbEncr: '',
        knbEncr1: '',
        knbEncr2: '',
        key: '', // fdp-select
        label: '' // fdp-select
      },
      prtr1ContrFmrt: [ // 친권1 계약자관계구분코드
        {key: '20', label: '부'},
        {key: '21', label: '모'},
        {key: '99', label: '기타(조부모 등)'}
      ],
      prtr1ContrFmrtSel: { // 선택한 친권1-계약자와의 관계
        key: '',
        label: ''
      },
      // 친권2
      lv_Prtr2Sel: { // 선택한 친권1
        custNm: '', // 이름
        chnlCustId: '', // 채널고객ID
        contrFmrt: '', // 계약자/예금주관계ID
        custCelno: '',
        custRrnId: '',
        contrDpstrSameYn: '', // 계약자예금주동일여부
        insrdDpstrSameYn: '', // 피보험자예금주동일여부
        dpstrIdpnYn: '', // 예금주독립여부
        knbEncr: '',
        key: '', // fdp-select
        label: '선택하세요' // fdp-select
      },
      prtr2ContrFmrt: [ // 친권2 계약자관계구분코드
        {key: '20', label: '부'},
        {key: '21', label: '모'},
        {key: '99', label: '기타(조부모 등)'}
        // {key: '90', label: '1인친권자'}
      ],
      prtr2ContrFmrtSel: { // 선택한 친권2-계약자와의 관계
        key: '',
        label: ''
      },
      //isOnePersonPrtrShow: false, // 1인친권자 확인
      onePersonPrtrShowMessage: '미성년자 포함 설계시 미성년자의 법정대리인이<br />2인인 경우 \'1인친권자(단독친권)\'은 선택불가합니다.<br /><br />진행하시겠습니까?', // 1인친권자 확인 문구
      prtrMcnstConfirmNo: '0', // 친권자 필수컨설팅대상 No
      showPrtrMcnstConfirm: false, // 친권자 필수컨설팅 표시여부
      //showPrtrMcnstConfirm2: false, // 계약자가 친권인이 아닌경우 메세지 표기 여부
      lv_AlertPop : null, //팝업
      // 피보험자의_계약자 관계구분 코드
      contrRltnScCd: [
        {key: '02', label: '배우자'},
        {key: '03', label: '자녀'},
        {key: '04', label: '부모'},
        {key: '05', label: '형제자매'},
        {key: '06', label: '조부모'},
        {key: '07', label: '외조부모'},
        {key: '08', label: '손자(녀)'},
        {key: '09', label: '시부모'},
        {key: '10', label: '장인장모'},
        {key: '11', label: '며느리, 사위'}
      ],
      contrRltnScCdSel: { // 선택한 피보험자의_계약자 관계구분 코드
        key: '',
        label: ''
      },
      // ASR210600884_[전자서명 ] 계약관계자 기재방식 변경 및 로직수정_(2021.07.20_PJO)_ END
      // ASR211000708_지정대리청구 로직 수정 및 신규 팝업 반영_사랑온 가입설계_20211109_youngki78.lee
      //isShowAlert: false, // 공통 메시지 팝업 출력
      msgDesc: '', // 공통 메시지 팝업 내용
      // showSinglePrtr: false, // 1인친권자 체크박스 출력 여부
      singlePrtrCheckbox: false, // 1인친권자 선택여부
      // ASR240200999_간편지대청 도입(김문준/240423)-------------------start
      apcInfoMsg2: '', // 지정대리청구인 안내 메세지 // ASR240200999_간편지대청 도입(김문준/240423)
      householdMembers_apc: [], // 지정대리용_세대원 목록 (계약자 제외)
      apcContrRltnScCdList_simple: [ // 간편_지정대리청구인-계약자와의관계
        {key: '11', label: '배우자'},
        {key: '12', label: '아들'},
        {key: '13', label: '딸'}
      ],
      apclamInfoSel_simple1: { // 간편_지정대리청구인-계약자와의관계 선택값
        key: '',
        label: ''
      },
      apclamInfoSel_simple2: { // 간편_지정대리청구인-계약자와의관계 선택값
        key: '',
        label: ''
      },
      isApcAsgnView_default1 : false, // 지대청1.고객카드선택_관계선택
      isApcAsgnView_default2 : false, // 지대청2.고객카드선택_관계선택
      isApcAsgnView_simple1 : false, // 간편지대청1.성명입력_관계선택
      isApcAsgnView_simple2 : false, // 간편지대청2.성명입력_관계선택
      apcCustNm_simple1 : '',
      apcCustNm_simple2 : '',
      simpAsgnClamAgntCd01 : '', // 지정대리인1_입력유형 / A 일반, B 간편
      simpAsgnClamAgntCd02 : '', // 지정대리인2_입력유형 / A 일반, B 간편
      custNmResult: true, // 이름 유효성 체크 결과
      custNmErrorMsg: '', // 이름 유효성 결과 에러 메시지
      custNmResult2: true, // 이름 유효성 체크 결과
      custNmErrorMsg2: '', // 이름 유효성 결과 에러 메시지
      validateRuleName: [
        v => !!v || '입력하세요.',
        v => this.custNmResult || this.custNmErrorMsg,
         v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView && this.simpAsgnClamAgntCd01 == 'B' && this.apcCustNm_simple1.trim() === '' ) ||  '필수입니다. 지정대리청구인1 성명이 입력되지 않았습니다.',
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView && this.simpAsgnClamAgntCd01 == 'B' && !this.$bizUtil.isCustNameValidation(this.apcCustNm_simple1.trim(), 'ZPER01') ) ||  '필수입니다. 유효하지 않은 고객명입니다.(한글10자이내, 영문/숫자/특수문자/공백 불가)'
      ],
      validateRuleName2: [
        v => !!v || '입력하세요.',
        v => this.custNmResult2 || this.custNmErrorMsg2,
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView2 && this.simpAsgnClamAgntCd02 == 'B' && this.apcCustNm_simple2.trim() === '' ) ||  '필수입니다. 지정대리청구인(2) 성명이 입력되지 않았습니다.',
        v => !(this.isAsgnProxyClamPsb && this.isApcAsgnView2 && this.simpAsgnClamAgntCd02 == 'B' && !this.$bizUtil.isCustNameValidation( this.apcCustNm_simple2.trim(), 'ZPER01') ) ||  '필수입니다. 유효하지 않은 고객명입니다.(한글10자이내, 영문/숫자/특수문자/공백 불가)'
      ],
      // ASR240200999_간편지대청 도입(김문준/240423)-------------------end
      // 초회보험료 신용카드 (ASR240400333_24.05 장한나)
      CARD_YN_CHK : 'N' // 업무컨피그(초회보험료 신용카드)=Y &&  보험료납입유형값RD=C 인경우 가능
      ,asgmMsg: '지정대리청구인을 설정하면<br>보험금수령이 용이합니다.<br>&quot;이름/관계&quot;만 입력하면 간단하게 설정되는<br>간편지정대리청구인을 지정하시겠습니까?'
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  
    if (this.$route.params) {
      this.lv_InputData = this.$route.params

      // 지정대리청구인 지정가능여부
      this.isAsgnProxyClamPsb = this.lv_InputData.isAsgnProxyClamPsb
      if ( this.isAsgnProxyClamPsb ) {
        // 보험유형관리코드RD (2021.05.31_PJO)
        this.zzInsrTypMgId = this.lv_InputData.zzInsrTypMgId
        this.fn_Init_ApcAsgn()
      }
      // ASR210800036 즉시연금 자동송금 세팅 기준 변경(김선모/2021.08.17 PJO)
      // 태아보험(특이보험유형코드RD=29) & 상품보험관계구성코드RD=60(태아_적립형주보험)有인 경우 , 계약자 예금주 동일한 경우만 전자서명진행가능
      // 전자서명   : ASIS TSSPS141D 계좌선택시 블러킹 -> TOBE TSSPS111P 계약자 !=예금주 선택 못하도록 수정
      // 모바일청약 : ASIS           계좌선택시 블러킹 -> TOBE TSSPS112P 계약자 !=예금주 선택 못하도록 수정
      let ptclrPrdTId = (this.lv_InputData.zzPtclrPrdTId === undefined || this.lv_InputData.zzPtclrPrdTId === '') ? ' ' : this.lv_InputData.zzPtclrPrdTId // 특이상품유형값
      let pdinsRCCId = (this.lv_InputData.zzPdinsRCCId === undefined || this.lv_InputData.zzPdinsRCCId === '') ? ' ' : this.lv_InputData.zzPdinsRCCId // 상품보험관계구성코드
      if (ptclrPrdTId === '29' && pdinsRCCId === '60') {
        // '주보험[적립] 선택시,\n예금주와 계약자는 동일해야합니다
        this.authTypes = [{key: '1', label: '예금주 / 계약자 동일'}]
      }
    }

    // 초기정보 조회
    let pId = this.lv_InputData.entplId
    this.jsonString = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: pId}}, vuchId: ' ', dpstrInfo: {chnlCustId: ''}})
    PSElstUtil.fn_EltrnSignBaseInfo('txTSSPS13S2', this.jsonString, this.fn_ServiceDataResultHandler)
    // 초회납입방법 신용카드납 on/off 체크 (ASR240400333_24.05 장한나)
    this.fn_CardYN ()
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명 : fn_ServiceDataResultHandler
    * 설명       : 전자서명 초기 데이타 호출 결과 컨트롤 함수
    ******************************************************************************/
    fn_ServiceDataResultHandler: async function (event) {
      let hoshInfoList = this.lv_HoshInfoList = event.hoshInfoList // 계약자/피보험자 정보
      
      if (hoshInfoList) {
        // 계약자 정보 설정 hoshInfoList[0]
        this.lv_contr.custNm = hoshInfoList[0].insrdNm // 계약자 이름
        this.lv_contr.age = hoshInfoList[0].age // 계약자 나이
        this.lv_contr.custRrnId1 = hoshInfoList[0].custRrnId.substring(0, 6) // 계약자 주민번호 앞자리
        this.lv_contr.custRrnId2 = hoshInfoList[0].custRrnId.substring(6, 7) + '******' // 계약자 주민번호 뒷 한자리 + 마스킹
        this.lv_contr.chnlCustId = hoshInfoList[0].detail.chnlCustId // 계약자 채널고객ID
        this.lv_contr.chnlCstgrId = hoshInfoList[0].detail.chnlCstgrId // 계약자 채널그룹ID
        this.lv_contr.custCelno = hoshInfoList[0].detail.celno // 계약자 전화번호 (22.02.08/PJO)
        if (hoshInfoList[0].agtFileId !== hoshInfoList[1].agtFileId) { // 계피상이
          this.contrInsrdSameYn = 'N' // 계피상이
          // 피보험자 정보 설정 hoshInfoList[1]
          this.lv_insured.custNm = hoshInfoList[1].insrdNm // 피보험자 이름
          this.lv_insured.age = hoshInfoList[1].age // 피보험자 나이
          this.lv_insured.custRrnId1 = hoshInfoList[1].custRrnId.substring(0, 6) // 피보험자 주민번호 앞자리
          this.lv_insured.custRrnId2 = hoshInfoList[1].custRrnId.substring(6, 7) + '******' // 피보험자 주민번호 뒷 한자리 + 마스킹
          this.lv_insured.chnlCustId = hoshInfoList[1].detail.chnlCustId // 피보험자 채널고객ID
          this.lv_insured.chnlCstgrId = hoshInfoList[1].detail.chnlCstgrId // 피보험자 채널그룹ID
        } else { // 계피동일
          this.contrInsrdSameYn = 'Y' // 계피동일
        }

        // 친권1, 친권2 표시 : 계약자 or 피보험자 미성년자인 경우(2021.07.20_PJO)
        if ( !this.$bizUtil.compareCurrentAge(hoshInfoList[0].custRrnId.substr(0, 6), this.standardAge) ||
          !this.$bizUtil.compareCurrentAge(hoshInfoList[1].custRrnId.substr(0, 6), this.standardAge) ) {
          this.showPrtr1Row = true // 친권1
          this.showPrtr2Row = true // 친권2
          // this.showSinglePrtr = true // 친권1 div 에 1인친권 라디오버튼 출력 여부
        }

        // 친권1선택정보 계약자 정보로 세팅
        // lv_Prtr1Sel: { // 선택한 친권1
        this.lv_Prtr1Sel.custNm = this.lv_contr.custNm // 이름
        this.lv_Prtr1Sel.chnlCustId = this.lv_contr.chnlCustId // 채널고객ID
        // this.lv_Prtr1Sel.contrFmrt: '' // 계약자/예금주관계ID
        // this.lv_Prtr1Sel.custCelno: ''
        // this.lv_Prtr1Sel.custRrnId: ''
        this.lv_Prtr1Sel.contrDpstrSameYn = this.contrInsrdSameYn // 계약자예금주동일여부
        // this.lv_Prtr1Sel.insrdDpstrSameYn: '', // 피보험자예금주동일여부
        // this.lv_Prtr1Sel.dpstrIdpnYn: '', // 예금주독립여부
        this.lv_Prtr1Sel.knbEncr = hoshInfoList[0].custRrnId
        this.lv_Prtr1Sel.knbEncr1 = this.lv_contr.custRrnId1
        this.lv_Prtr1Sel.knbEncr2 = this.lv_contr.custRrnId2

        this.lv_Prtr1Sel.key = '' // fdp-select
        this.lv_Prtr1Sel.label = '' // fdp-select
        this.lv_Prtr1Sel.name = this.lv_contr.custNm // 이름
        this.lv_Prtr1Sel.celno = this.lv_contr.custCelno // 휴대폰번호(2022.02.08)

        // 피보험자의 계약자와의 관계
        // (2) 피보험자가 미성년자인 경우(자녀만 선택 가능)
        if (!this.$bizUtil.compareCurrentAge(hoshInfoList[1].custRrnId.substr(0, 6), this.standardAge)) {
          this.contrRltnScCd = [{key: '03', label: '자녀'}]
        }

        // 정보활용동의고객상세조회 (F1MDI0159 호출)
        this.fn_SelhouseholdMembers()
      } else {
        console.log('#### fn_ServiceDataResultHandler 오류')
      }

      //AML 대응 처리 추가된 컬럼 값 셋팅 전자서명 완료시 부터 AML 처리 기준값 
      //let isAmlCntr      = PSAmlUtil.fn_IsAmlPrfmc('1',this.lv_InputData.jsonInputData) // 계약자 AML 대상여부
      //let isAmlPrtr01    = PSAmlUtil.fn_IsAmlPrfmc('2',this.lv_InputData.jsonInputData) // 친권자01 AML 대상여부
      this.isAmlDthBnfr01 = PSAmlUtil.fn_IsAmlPrfmc('3',this.lv_InputData.jsonInputData) // 사망수익자01 AML 대상여부

      //AML 사망수익자 채널고객ID 구하기
      if (this.isAmlDthBnfr01) {
        let dthBnfr01Info = await PSAmlUtil.fn_GetBnfrInfo(this, event.bnfrInfoList)
        //let dthBnfr01Info = PSAmlUtil.fn_GetBnfrInfo(this, event.bnfrInfoList)
        if(dthBnfr01Info){
          this.amlDthBnfr01_chnlCustId = dthBnfr01Info.agtFileId	//AML사망수익자채널고객ID
        }
      }


      // 수익자 화면 출력
      let bnfrInfoList = event.bnfrInfoList
      let bnfrInfo1_custNm = new Set()
      let bnfrInfo2_custNm = new Set()

      this.bnfrInfo31 = {} //만기수익자
      this.bnfrInfo32 = {} //분할수익자
      this.bnfrInfo33 = {} //연금수익자
      this.bnfrInfo34 = {} //상해수익자
      this.bnfrInfo35 = [] //사망수익자 (최대4명)
      this.bnfrInfo39 = {} //연금후수익자
      this.isBnfrInfo31 = false
      this.isBnfrInfo32 = false
      this.isBnfrInfo33 = false
      this.isBnfrInfo34 = false
      this.isBnfrInfo35 = false
      this.isBnfrInfo39 = false

      if (bnfrInfoList) {
        for (let i = 0; i < bnfrInfoList.length; i++ ) {
          let rrn = { 
            knbEncr1 : bnfrInfoList[i].custRrnId.substring(0, 6), // 주민번호 앞자리
            knbEncr2 : bnfrInfoList[i].custRrnId.substring(6, 7) + '******'
          }
          switch (bnfrInfoList[i].bnfrRolCd) {
             case '31': // 만기수익자
               this.bnfrInfo31 = Object.assign(bnfrInfoList[i], rrn)
               this.isBnfrInfo31 = true
               bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
               break
             case '32': // 분할수익자
               this.bnfrInfo32 = Object.assign(bnfrInfoList[i], rrn)
               this.isBnfrInfo32 = true
               bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
               break
             case '33': // 연금수익자
               this.bnfrInfo33 = Object.assign(bnfrInfoList[i], rrn)
               this.isBnfrInfo33 = true
               bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
               break
             case '34': // 상해수익자
               this.bnfrInfo34 = Object.assign(bnfrInfoList[i], rrn)
               this.isBnfrInfo34 = true
               bnfrInfo2_custNm.add(bnfrInfoList[i].custNm)
               break
             case '35': // 사망수익자
               this.bnfrInfo35.push(Object.assign(bnfrInfoList[i], rrn))
               this.isBnfrInfo35 = true
               bnfrInfo2_custNm.add(bnfrInfoList[i].custNm)
               break
             case '39': // 연금후수익자
               this.bnfrInfo39 = Object.assign(bnfrInfoList[i], rrn)
               this.isBnfrInfo39 = true
               bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
               break
          }
        }
        this.bnfrInfo1_custNm = Array.from(bnfrInfo1_custNm).toString()
        this.bnfrInfo2_custNm = Array.from(bnfrInfo2_custNm).toString()
      } 
    },

    /************************************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ************************************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    },
    
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 헤더 Back Button 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      // 청약서발행으로 이동
      //this.$MenuManager.fn_RouterPushSrnId('MSPPS220M')
      this.fn_ConfirmExit('전자서명 진행을 종료하고 청약서발행현황으로 이동하시겠습니까?', 'MSPPS220M')
    },// fn_HeaderBackBtnHandler

    /******************************************************************************
    * Function명 : fn_DpstrChangeClicked
    * 설명       : 예금주/계약자상이 선택
    ******************************************************************************/
    fn_DpstrChangeClicked () {
      if (this.authTypeSel[0].key === '2') {
        let msg = ''
        if ( this.CARD_YN_CHK === 'Y' ) { // 신용카드 문구추가 (ASR240400333_24.05 장한나)
          msg = 'ᆞ 예금주 상이건의 경우,<br />반드시 예금주 본인명의 휴대폰으로만 인증을 진행해야 합니다.<br /><br />ᆞ 예금주 상이건의 경우,<br />초회납입방법 가상계좌,간편납입, 신용카드납입의 경우 처리 불가합니다.<br /><br />진행하시겠습니까?'
        } else {
          msg = 'ᆞ 예금주 상이건의 경우,<br />반드시 예금주 본인명의 휴대폰으로만 인증을 진행해야 합니다.<br /><br />ᆞ 예금주 상이건의 경우,<br />초회납입방법 가상계좌,간편납입의 경우 처리 불가합니다.<br /><br />진행하시겠습니까?'          
        }
        this.fn_DpstrChangeConfirm(msg)
      }
    },

    /******************************************************************************
    * Function명 : fn_DpstrChangeConfirm
    * 설명       : 예금주/계약자상이 Confirm 팝업 호출
    ******************************************************************************/
    fn_DpstrChangeConfirm (contents) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: contents,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop);
          },
          onPopupClose: () => {
            this.authTypeSel = [{key: '1', label: '예금주 / 계약자 동일'}]
            this.$bottomModal.close(this.lv_AlertPop);
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_SelhouseholdMembers
    * 설명       : 세대원 정보 조회
    ******************************************************************************/
    fn_SelhouseholdMembers () {
      // 1. 예금주 초기화
      this.householdMembers = []
      this.householdMembers_apc = [] // 지정대리청구_가족카드리스트(간편지대청 입력추가됨)
      this.hsmbDpstr = {key: '', label: '선택하세요', style: true, name: '', chnlCustId: '', isMcnstYN: ''}
      this.contrRltn = {key: '', label: ''}
      // 2. 세대원 조회
      let pParams = {
        'cnsltNo': this.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
        'chnlCstgrId': this.lv_contr.chnlCstgrId // 채널고객그룹ID
      }

      let trnstId = 'txTSSCM10S5' // 정보활용동의고객상세조회 (F1MDI0159 호출)
      let auth = 'S'
      let lv_Vm = this

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null && response.body.iCCustInfoPrcusCnsntVO !== null) {
            // 2.2 세대원 목록 설정
            let t_CnsntList = response.body.iCCustInfoPrcusCnsntVO
            let i = 0
            let currentYmd = PSDateUtil.fn_CurrentDate()
            // 2.3 반복, 세대원 목록 구성
            t_CnsntList.forEach((item) => {
              // 2.3.1 계약자는 세대원 목록에서 제외
              if ( item.chnlCustId === lv_Vm.lv_contr.chnlCustId ) {
                return true // return true -> continue
              }
              let t_member = {}
              i++
              // 2.3.1 화면 표시영역
              t_member.key = i.toString()
              t_member.label = item.custNm
              t_member.style = true // 필수컨설팅동의 : N
              // 2.3.2 데이터 영역
              t_member.name = item.custNm
              t_member.chnlCustId = item.chnlCustId
              t_member.knbEncr = item.knbEncr
              t_member.knbEncr1 = item.knbEncr.substring(0, 6) // 20210720
              t_member.knbEncr2 = item.knbEncr.substring(6, 12) // 20210720
              t_member.isMcnstYN = 'N' // 필수컨설팅동의여부
              t_member.celno = item.celno // 휴대폰번호 (2022.02.08_PJO)

              // 정보활용동의일자14 = 필수컨설팅동의일자
              if (item.infoPrcusCnsntYmd14 >= currentYmd) {
                t_member.style = false // 필수컨설팅동의 : Y
                t_member.isMcnstYN = 'Y' // 필수컨설팅동의여부
              }
              // 목록에 1건씩 추가
              lv_Vm.householdMembers.push(t_member)
              lv_Vm.householdMembers_apc.push(t_member) // 간편지대청
            })
            // 지정대리청구 가능할 경우
            if ( lv_Vm.isAsgnProxyClamPsb ) { 
              lv_Vm.fn_Init_ApcHouseholdMember()
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /*********************************************************
     * Function명: fn_ShowMSPCM304P
     * 설명: 세대조정 창을 연다. (구.fn_showTSSCM121M)
     *********************************************************/
    fn_ShowMSPCM304P () {
      const rpblPopup = this.$bottomModal.open(MSPCM304P, {
        properties: {
          pCustInfo: {
            chnlCustId: this.lv_contr.chnlCustId, // 채널고객ID
            cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId        // 담당컨설턴트ID
          },
          pCallViewCd: 'PS'
        },
        listeners: { 
          // 대상팝업 onPopupClose $emit 이벤트명 
          onPopupClose : (rtnData) => {            
            this.$bottomModal.close(rpblPopup) // 모달 닫기        
            this.fn_HideContPtcpInfo()
          },
          onPopupConfirm: (rtnData) => {            
            this.$bottomModal.close(rpblPopup) // 모달 닫기   
            this.fn_HideContPtcpInfo()
          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    
    /******************************************************************************
    * Function명 : fn_ShowMSPCM301D
    * 설명       : 고객개인정보 수정 팝업 열기 (구.fn_showTSSCM122D)
    * ASR211200564(2022.02.08/PJO)
    ******************************************************************************/
    fn_ShowMSPCM301D (targetChnlCustId) {
      const custPInfoPopup = this.$bottomModal.open(MSPCM300P, {
        properties: {
          callerId : this.$options.screenId, 
          viewId : 'MSPCM301D',
          pChnlCustId : targetChnlCustId,
          pCnsltNo : this.getStore('userInfo').getters.getUserInfo.userId,
        },
        listeners: { 
          // 대상팝업 onPopupClose $emit 이벤트명 
          onPopupClose : (rtnData) => {            
            this.$bottomModal.close(custPInfoPopup) // 모달 닫기        
            this.fn_HideContPtcpInfo()
          },
          onPopupConfirm : (rtnData) => {            
            this.$bottomModal.close(custPInfoPopup) // 모달 닫기   
            this.fn_HideContPtcpInfo()
          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },
    
    /*********************************************************
     * Function명: fn_HideContPtcpInfo
     * 설명: 세대조정, 고객정보 수정 창을 닫는다.
     *********************************************************/
    fn_HideContPtcpInfo () {
      
      // 예금주, 친권1, 친권2 선택값 초기화
      if ( this.authTypeSel[0].key === '2' ) { // 예금주상이
        console.log('.....예금주상이')
        this.lv_dpstr = {custNm: '', chnlCustId: '', contrFmrt: ''}
        this.contrRltn = {key: '', label: ''}
      }
      this.lv_Prtr1Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
      this.prtr1ContrFmrtSel = {key: '', label: ''}
      this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '선택하세요', name: '', style: false}
      this.prtr2ContrFmrtSel = {key: '', label: ''}
      this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화

      // 공동친권 초기화
      this.isCoprPrtrMuagr = false
      // 지정대리청구 콤보 선택값 초기화 , DB저장값 초기화 / ASR220300068_보완 (2022.03.10 PJO)
      this.fn_Init_ApcAsgn()

      this.isApcAsgnView = false
      this.isApcAsgnView2 = false
      // 지정대리청구인 선택값 초기화 (고객명,채널구분코드)
      this.fn_InitApcInfo('A1')
      this.fn_InitApcInfo('A2')
      // this.lv_asgnProxyClamInfoSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
      // this.asgnProxyClamContrRltnScCdSel = {key: '', label: ''}

      // 계약정보 초기조회
      let pId = this.lv_InputData.entplId
      this.jsonString = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: pId}}, vuchId: ' ', dpstrInfo: {chnlCustId: ''}})
      PSElstUtil.fn_EltrnSignBaseInfo('txTSSPS13S2', this.jsonString, this.fn_ServiceDataResultHandler)
    },
    /*********************************************************
     * Function명: fn_ChkHsmbDpstr
     * 설명: 예금주의 필수컨설팅동의여부 확인
     *********************************************************/
    fn_ChkHsmbDpstr (param) {
      this.hsmbDpstr = {
          key: param.key,
          label: param.label,
          style: param.style,
          name: param.name,
          chnlCustId: param.chnlCustId
      }
      // 필수컨설팅동의여부가 N인경우
      if (param.isMcnstYN === 'N') {
        this.fn_BottomConfirm("필수컨설팅 동의가 없는 고객은 예금주 지정이 불가능합니다. 고객등록동의 화면으로 이동하시겠습니까?")
      } else if (!this.$bizUtil.compareCurrentAge(param.knbEncr.substr(0, 6), this.standardAge)) {
        this.getStore('confirm').dispatch('ADD', '미성년자는 예금주 지정이 불가합니다.')
        this.hsmbDpstr = {key: '', label: '선택하세요', style: true, name: '', chnlCustId: '', isMcnstYN: ''}
      }
    },

    /******************************************************************************
     * Function명 : fn_BottomConfirm
     * 설명       : 고객등록동의 Confirm 팝업 호출
     ******************************************************************************/
    fn_BottomConfirm (contents) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: contents,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop);
            this.fn_MoveMSPCM001M('D', 'N')
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop);
            this.fn_HsmbDpstrOnNegative()
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_HsmbDpstrOnNegative
     * 설명: 고객등록동의화면 이동 -> 아니오
     *********************************************************/
    fn_HsmbDpstrOnNegative () {
      // 예금주 선택 초기화
      this.hsmbDpstr = {key: '', label: '', style: true, name: '', chnlCustId: '', isMcnstYN: ''}
    },
    /******************************************************************************
    * Function명 : fn_MoveMSPCM001M
    * 설명       : 선택된 고객정보를 가지고 고객등록동의 화면으로 이동한다.
    ******************************************************************************/
    fn_MoveMSPCM001M (userType, isMcnstYN = null) {
      if (userType === 'D') {
        // D:예금주
        if (this.hsmbDpstr.chnlCustId.trim() !== '') {
          //this.$router.push({name: 'MSPCM001M', params: {chnlCustId: this.hsmbDpstr.chnlCustId}})
          if (isMcnstYN === 'N') {
            this.$router.push({name: 'MSPCM050M', params: {custNm: this.hsmbDpstr.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData, infoPrcusCnsntTypCd : '17'}})
          }
          else {
            this.$router.push({name: 'MSPCM050M', params: {custNm: this.hsmbDpstr.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData}})
          }
        } else {
          this.getStore('confirm').dispatch('ADD', '예금주가 선택되지 않았습니다.')
        }
      } else if (userType === 'A1') {
        // A:지정대리청구인
        if (this.apcInfo.chnlCustId.trim() !== '') {
          //this.$router.push({name: 'MSPCM001M', params: {chnlCustId: this.apcInfo.chnlCustId}})
          this.$router.push({name: 'MSPCM050M', params: {custNm: this.apcInfo.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData}})
        } else {
          this.getStore('confirm').dispatch('ADD', '지정대리청구인이 선택되지 않았습니다.')
        }
      } else if (userType === 'A2') {
        // A:지정대리청구인2
        if (this.apcInfo2.chnlCustId.trim() !== '') {
          //this.$router.push({name: 'MSPCM001M', params: {chnlCustId: this.apcInfo.chnlCustId}})
          this.$router.push({name: 'MSPCM050M', params: {custNm: this.apcInfo2.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData}})
        } else {
          this.getStore('confirm').dispatch('ADD', '지정대리청구인2가 선택되지 않았습니다.')
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_MoveMSPPS420M
    * 설명       : 예금주 선택 확정 (구.fn_moveTSSPS110M)
    ******************************************************************************/
    fn_MoveMSPPS420M () {
      // 1. 예금주 정보 설정
      let contrDpstrSameYn = 'N' // 계약자예금주동일여부
      let insrdDpstrSameYn = 'N' // 피보험자예금주동일여부
      let dpstrIdpnYn = 'N' // 예금주독립여부

      if( !this.fn_ValidCheck() ) {
        return
      }

      // 1.1 예금주/계약자 동일
      if ( this.authTypeSel[0].key === '1' ) {     
        //validate Check START
        let tItem = []
        let tRefs    = []

        // 1.1.1 계약자정보 -> 예금주정보
        this.lv_dpstr.custNm = this.lv_contr.custNm // 예금주 이름
        this.lv_dpstr.chnlCustId = this.lv_contr.chnlCustId // 예금주 채널고객ID
        this.lv_dpstr.contrFmrt = '01' // 계약자,예금주관계ID :01:본인
        contrDpstrSameYn = 'Y' // 계약자예금주동일여부
      } else if ( this.authTypeSel[0].key === '2' ) {     
                
        // 1.2 예금주/계약자 상이
        // 1.2.1 예금주 값 검증
        if ( this.hsmbDpstr.key.trim() === '' ) {
          this.getStore('confirm').dispatch('ADD', '예금주가 선택되지 않았습니다.')
          return
        }
        // 1.2.2 계약자관계 검증
        if ( this.contrRltn.key.trim() === '' ) {
          this.getStore('confirm').dispatch('ADD', '계약자와의 관계가 선택되지 않았습니다.')
          return
        }
        // 1.2.3 예금주/피보험자 동일여부
        if ( this.hsmbDpstr.chnlCustId === this.lv_insured.chnlCustId ) {
          insrdDpstrSameYn = 'Y' // 피보험자예금주동일여부
        } else {
          dpstrIdpnYn = 'Y' // 예금주독립여부
        }
        // 1.2.4 예금주
        this.lv_dpstr.custNm = this.hsmbDpstr.name // 예금주 이름
        this.lv_dpstr.chnlCustId = this.hsmbDpstr.chnlCustId // 예금주 채널고객ID
        this.lv_dpstr.contrFmrt = this.contrRltn.key // 계약자,예금주관계ID
      }

      // 1.3 예금주정보 설정
      this.lv_dpstr.contrDpstrSameYn = contrDpstrSameYn // 계약자예금주동일여부
      this.lv_dpstr.insrdDpstrSameYn = insrdDpstrSameYn // 피보험자예금주동일여부
      this.lv_dpstr.dpstrIdpnYn = dpstrIdpnYn // 예금주독립여부
      this.lv_InputData.lv_dpstr = this.lv_dpstr

      // 1.4 지정대리청구인 값설정 / TTSD00002저장값
      this.lv_InputData.lv_apcInfo = {}
      this.lv_InputData.lv_apcInfo.apcAsgnYn = 'N' // 지정대리청구지정여부
      this.lv_InputData.lv_apcInfo.apcPrsonAplScCd = '' // 지정대리청구인신청구분코드(20210531_PJO)
      this.lv_InputData.lv_apcInfo.apcChnlCustId = '' // 지정대리청구 모바일영업채널고객ID
      this.lv_InputData.lv_apcInfo.apcCustNm = '' // 지정대리청구명
      this.lv_InputData.lv_apcInfo.contrRltnCd = '' // 계약자관계코드
      // 2인지정가능
      this.lv_InputData.lv_apcInfo.apcChnlCustId02 = '' // 지정대리청구 모바일영업채널고객ID2
      this.lv_InputData.lv_apcInfo.apcCustNm02 = '' // 지정대리청구명2
      this.lv_InputData.lv_apcInfo.contrRltnCd02 = '' // 계약자관계코드2
      // ASR240200999_간편지대청 도입(김문준/240423)
      this.lv_InputData.lv_apcInfo.simpAsgnClamAgntCd01 = '' // 지정대리인1_입력유형
      this.lv_InputData.lv_apcInfo.simpAsgnClamAgntCd02 = '' // 지정대리인2_입력유형

      // 지정대리청구인 세팅하지 않아도, 지정대리 청구 가능시에는 선택값을 저장한다.
      // ex)  지정대리청구지정여부(apcAsgnYn) N 이어도  , 지정대리청구인신청구분코드(apcPrsonAplScCd) , M1(미지정) M2(지정불가) 는 저장
      if (this.isAsgnProxyClamPsb) { // 지정대리청구인 지정가능여부
        if( !this.fn_ChkApcValidation() ) {
          console.log('---------지대청 체크 false')
          return
        }
        console.log('----------흐르니?')
        // if (this.apcPrsonAplScSel.key === '' ) {
        //   this.getStore('confirm').dispatch('ADD', '[계약자] 정보를 펼쳐 지정대리청구인을 선택해 주세요.') //문구수정(ASR231001107_231107_PJO)
        //   return
        // }
        // this.lv_InputData.lv_apcInfo.apcPrsonAplScCd = this.apcPrsonAplScCd // 지정대리청구인신청구분코드(20210531_PJO)
        this.lv_InputData.lv_apcInfo.apcPrsonAplScCd = this.apcPrsonAplScSel.key // 지정대리청구인신청구분코드(20210531_PJO)this.apcPrsonAplScSel.key
        if (this.isApcAsgnView) {
          /*if ( this.simpAsgnClamAgntCd01 == 'A')  {
            if ( this.apcInfo.key.trim() === '' ) {
              this.getStore('confirm').dispatch('ADD', '지정대리청구인이 선택되지 않았습니다.')
              return
            }
            if ( this.apclamInfoSel.key.trim() === '' ) {
              this.getStore('confirm').dispatch('ADD', '지정대리청구인 피보험자와의 관계가 선택되지 않았습니다.')
              return
            }
          }
          // ASR240200999_간편지대청 도입(김문준/240423)
          if ( this.simpAsgnClamAgntCd01 == 'B')  {
            if( this.apcCustNm_simple1.trim() === '' ) {
              this.getStore('confirm').dispatch('ADD', '지정대리청구인이 선택되지 않았습니다.')
              return
            }
            if( thsi.apclamInfoSel_simple1.key.trim() === '' ) {
              this.getStore('confirm').dispatch('ADD', '지정대리청구인 피보험자와의 관계가 선택되지 않았습니다.')
              return
            }
          }*/
          // 지정대리청구인 지정한 경우
          this.lv_InputData.lv_apcInfo.apcAsgnYn = 'Y' // 지정대리청구지정여부
          this.lv_InputData.lv_apcInfo.simpAsgnClamAgntCd01 = this.simpAsgnClamAgntCd01
          if ( this.simpAsgnClamAgntCd01 == 'A')  {
            this.lv_InputData.lv_apcInfo.apcChnlCustId = this.apcInfo.chnlCustId // 지정대리청구 모바일영업채널고객ID
            this.lv_InputData.lv_apcInfo.apcCustNm = this.apcInfo.name // 지정대리청구명
            // 지정대리청구인 관계코드
            let tmpCdKey = this.apclamInfoSel.key
            if (tmpCdKey === '61' || tmpCdKey === '62' || tmpCdKey === '63' || tmpCdKey === '64' || tmpCdKey === '65' ||
                tmpCdKey === '66' || tmpCdKey === '66' || tmpCdKey === '67' || tmpCdKey === '68' ) {
              this.lv_InputData.lv_apcInfo.contrRltnCd = '06' // 계약자관계코드 06:친족
            } else {
              this.lv_InputData.lv_apcInfo.contrRltnCd = tmpCdKey
            }
          } else if ( this.simpAsgnClamAgntCd01 == 'B')  { // 직접입력
            this.lv_InputData.lv_apcInfo.apcChnlCustId = ''
            this.lv_InputData.lv_apcInfo.apcCustNm = this.apcCustNm_simple1
            this.lv_InputData.lv_apcInfo.contrRltnCd = this.apclamInfoSel_simple1.key
          }
        }
        // 지정대리청구인_2인가능_수정_START(ASR210500282_20210531_PJO)
        if (this.isApcAsgnView2) {
          // 2인지정가능
          this.lv_InputData.lv_apcInfo.apcChnlCustId02 = '' // 지정대리청구 모바일영업채널고객ID2
          this.lv_InputData.lv_apcInfo.apcCustNm02 = '' // 지정대리청구명2
          this.lv_InputData.lv_apcInfo.contrRltnCd02 = '' // 계약자관계코드2

          // if ( this.apcInfo2.key.trim() === '' ) {
          //   this.getStore('confirm').dispatch('ADD', '지정대리청구인(2)이 선택되지 않았습니다.')
          //   return
          // }
          // if ( this.apclamInfoSel2.key.trim() === '' ) {
          //   this.getStore('confirm').dispatch('ADD', '피보험자와의 관계(2)가 선택되지 않았습니다.')
          //   return
          // }
          // 지정대리청구인 지정한 경우
          this.lv_InputData.lv_apcInfo.simpAsgnClamAgntCd02 = this.simpAsgnClamAgntCd02
          if ( this.simpAsgnClamAgntCd02 == 'A')  {
            this.lv_InputData.lv_apcInfo.apcChnlCustId02 = this.apcInfo2.chnlCustId // 지정대리청구 모바일영업채널고객ID
            this.lv_InputData.lv_apcInfo.apcCustNm02 = this.apcInfo2.name // 지정대리청구명
            // 지정대리청구인 관계코드
            let tmpCdKey = this.apclamInfoSel2.key
            if (tmpCdKey === '61' || tmpCdKey === '62' || tmpCdKey === '63' || tmpCdKey === '64' || tmpCdKey === '65' ||
                tmpCdKey === '66' || tmpCdKey === '66' || tmpCdKey === '67' || tmpCdKey === '68' ) {
              this.lv_InputData.lv_apcInfo.contrRltnCd02 = '06' // 계약자관계코드 06:친족
            } else {
              this.lv_InputData.lv_apcInfo.contrRltnCd02 = tmpCdKey
            }
          } else if ( this.simpAsgnClamAgntCd02 == 'B') {
            this.lv_InputData.lv_apcInfo.apcChnlCustId02 = '' // 지정대리청구 모바일영업채널고객ID2
            this.lv_InputData.lv_apcInfo.apcCustNm02 = this.apcCustNm_simple2 // 지정대리청구명2
            this.lv_InputData.lv_apcInfo.contrRltnCd02 = this.apclamInfoSel_simple2.key // 계약자관계코드2
          }
        }
        console.log(' MSPPS410M.지정대리청구정보:\n' + JSON.stringify(this.lv_InputData.lv_apcInfo))
        // 지정대리청구인_2인가능_수정_END(ASR210500282_20210531_PJO)
      } //------------------------ 지정대리인 체크_END

      // START (ASR210600884_20210720_PJO)
      // 피보험자_계약
      if ( this.contrInsrdSameYn === 'N' ) {
        if ( this.contrRltnScCdSel.key === '' ) {
          this.getStore('confirm').dispatch('ADD', '피보험자의 계약자와의 관계가 선택되지 않았습니다.')
          return
        } else {
          this.lv_InputData.insrdContrRltnScCd = this.contrRltnScCdSel.key // 피보험자_계약자와의관계구분코드
        }
      }
      if ( this.showPrtr1Row === true && (this.showPrtr2Row === true || (this.showPrtr2Row === false && this.singlePrtrCheckbox === true) ) ) {
        // 친권자1,2 선택여부 체크
        // 친권1
        if (this.prtr1ContrFmrtSel.key === '') {
          this.getStore('confirm').dispatch('ADD', '친권1 관계가 선택되지 않았습니다.')
          return
        } else if (this.prtr1ContrFmrtSel.key === '99') { // 99 기타(조부모 등)
          this.prtr1ContrFmrtSel = {key: '', label: ''}
          //this.showPrtrMcnstConfirm2 = true
          this.fn_Confirm('계약자가 친권인이 아닌 경우 전자서명 불가합니다.<br />전자서명 종료 후 모바일 청약 혹은<br />지류청약으로 진행하시겠습니까?')
          return
        } else if (this.$bizUtil.isEmpty(this.lv_Prtr1Sel.celno)) { // (2022.02.08_PJO)
          this.getStore('confirm').dispatch('ADD', '친권자1의 휴대폰 번호가 없습니다.')
          return
        }
        // 친권2
        if (!this.singlePrtrCheckbox && this.prtr2ContrFmrtSel.key === '') {
          this.getStore('confirm').dispatch('ADD', '친권2 관계가 선택되지 않았습니다.')
          return
        } else if (!this.singlePrtrCheckbox && this.prtr2ContrFmrtSel.key === '99') { // 99 기타(조부모 등)
          this.prtr2ContrFmrtSel = {key: '', label: ''}
          //this.showPrtrMcnstConfirm2 = true
          this.fn_Confirm('계약자가 친권인이 아닌 경우 전자서명 불가합니다.<br />전자서명 종료 후 모바일 청약 혹은<br />지류청약으로 진행하시겠습니까?')
          this.componentKey += 1
          // this.getStore('confirm').dispatch('ADD', '계약자가 친권인이 아닌 경우 전자서명 불가합니다.\n\n모바일청약 혹은 지류청약 진행바랍니다.')
          return
        } else if (this.singlePrtrCheckbox === false) { // this.prtr2ContrFmrtSel.key !== '90') { // 미선택 , 조부무, 1인친권 이 아닌데..폰번호 없으면 // (2022.02.08_PJO)
          if (this.$bizUtil.isEmpty(this.lv_Prtr2Sel.celno)) {
            this.getStore('confirm').dispatch('ADD', '친권자2의 휴대폰 번호가 없습니다.')
            return
          }
        }
        // 친권1, 친권2 관계 검증
        if (!this.singlePrtrCheckbox && this.prtr1ContrFmrtSel.key === this.prtr2ContrFmrtSel.key) {
          this.prtr2ContrFmrtSel = {key: '', label: ''}
          this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
          this.getStore('confirm').dispatch('ADD', '친권1과 동일한 관계가 지정되었습니다.')
          return
        }
        this.lv_InputData.prtrNm01 = this.lv_Prtr1Sel.name // 친권자명01
        this.lv_InputData.prtrChnlCustId01 = this.lv_Prtr1Sel.chnlCustId // 친권자채널고객ID01
        this.lv_InputData.prtrInsrdRltnScCd01 = this.prtr1ContrFmrtSel.key // 친권자피보험자와의관계구분코드01
        this.lv_InputData.prtrNm02 = this.lv_Prtr2Sel.name // 친권자명02
        this.lv_InputData.prtrChnlCustId02 = this.lv_Prtr2Sel.chnlCustId // 친권자채널고객ID02
        this.lv_InputData.prtrInsrdRltnScCd02 = this.singlePrtrCheckbox ? '90' : this.prtr2ContrFmrtSel.key // 친권자피보험자와의관계구분코드02

        // 친권자 휴대폰번호 추가 ASR211200564_친권인 연락처 사전정보입력(2022.02.08/PJO)
        this.lv_InputData.prtrTelnoEncr01 = this.$bizUtil.replaceAll(this.lv_Prtr1Sel.celno, '-', '') // 계약관계인전화번호암호화
        this.lv_InputData.prtrTelnoEncr02 = this.$bizUtil.replaceAll(this.lv_Prtr2Sel.celno, '-', '') // 계약관계인전화번호암호화
      }
      // alert('화면이동')
      // END (ASR210600884_20210720_PJO)
      // 1.5 화면 이동
      this.$MenuManager.fn_RouterPushSrnId('MSPPS420M', this.lv_InputData)
    },
    /******************************************************************************
    * Function명 : fn_ValidCheck
    * 설명       : RULL MESSAGE 다음 버튼 이벤트시 
    ******************************************************************************/
    fn_ValidCheck () {
      let rst = true
      //validate Check START
      let tItem = []
      let tRefs    = []
      // 피보험자_계약
      if ( this.contrInsrdSameYn === 'N' ) {
        if ( this.contrRltnScCdSel.key === '' ) {
          let bApcAplScValid12 = this.$refs['pName'].isValid()
          tItem.push(bApcAplScValid12)
          if(!bApcAplScValid12){
            this.$refs['mspExp3'].isExpand =  true
            tRefs.push('pName')
          } 
        }
      }

      if ( this.authTypeSel[0].key === '2' ) {//예상        
        if ( this.hsmbDpstr.key.trim() === '' ) {
          let bApcAplScValid1 = this.$refs['yName'].isValid()
          tItem.push(bApcAplScValid1)
          if(!bApcAplScValid1){
            this.$refs['mspExp2'].isExpand =  true
            tRefs.push('yName')
          }
        }

        if ( this.contrRltn.key.trim() === '' ) {
          let bApcAplScValid2 = this.$refs['yContr'].isValid()
          tItem.push(bApcAplScValid2)
          if(!bApcAplScValid2){
            this.$refs['mspExp2'].isExpand =  true
            tRefs.push('yContr')
          }
        }        
      }
      
      if (this.isAsgnProxyClamPsb) { // 지정대리청구인 지정가능여부
          if (this.$bizUtil.isEmpty(this.apcPrsonAplScSel.key)) {
            let bApcAplScValid3 = this.$refs['apcPrsonAplScSel'].isValid()
            tItem.push(bApcAplScValid3)
            if(!bApcAplScValid3){
              this.$refs['mspExp4'].isExpand =  true
              tRefs.push('apcPrsonAplScSel')
            }
          }

          if (!this.$refs['mspExp4'].isExpand && this.simpAsgnClamAgntCd01 == 'B' && ( this.$bizUtil.isEmpty(this.apcCustNm_simple1) || this.$bizUtil.isEmpty(this.apclamInfoSel_simple1.key))) {
            let bApcAplScValid3 = this.$refs['apcPrsonAplScSel'].isValid()
            tItem.push(bApcAplScValid3)
            if(!bApcAplScValid3){
              this.$refs['mspExp4'].isExpand =  true
              tRefs.push('apcPrsonAplScSel')
            }
          }

          // 지정대리청구인_1인
          if (this.isApcAsgnView) {
             if ( this.simpAsgnClamAgntCd01 === '' ) {
                let bApcAplScValid4 = this.$refs['jOneDa'].isValid()
                tItem.push(bApcAplScValid4)
                if(!bApcAplScValid4){
                  this.$refs['mspExp4'].isExpand =  true
                  tRefs.push('jOneDa')
                }
             } else if ( this.simpAsgnClamAgntCd01 == 'A')  { // 일반입력
                if ( this.$bizUtil.isEmpty(this.apcInfo.key)) { // 240402
                  let bApcAplScValid4 = this.$refs['jOneDa'].isValid()
                  tItem.push(bApcAplScValid4)
                  if(!bApcAplScValid4){
                    this.$refs['mspExp4'].isExpand =  true
                    tRefs.push('jOneDa')
                  }
                }
                if (this.$bizUtil.isEmpty( this.apclamInfoSel.key)) { // 240402
                  let bApcAplScValid5 = this.$refs['jOneCrtn'].isValid()
                  tItem.push(bApcAplScValid5)
                  if(!bApcAplScValid5){
                    this.$refs['mspExp4'].isExpand =  true
                    tRefs.push('jOneCrtn')
                  }
                }
             } else if ( this.simpAsgnClamAgntCd01 == 'B')  { // 직접입력
                if( this.apcCustNm_simple1.trim() === '' ) {
                  let bApcAplScValid6 = this.$refs['custNmRef1'].isValid()
                  tItem.push(bApcAplScValid6)
                  if(!bApcAplScValid6){
                    this.$refs['mspExp4'].isExpand =  true
                    tRefs.push('custNmRef1')
                  }
                }
                if (!this.$bizUtil.isCustNameValidation(this.apcCustNm_simple1.trim(), 'ZPER01')) {
                  let bApcAplScValid6 = this.$refs['custNmRef1'].isValid()
                  tItem.push(bApcAplScValid6)
                  if(!bApcAplScValid6){
                    this.$refs['mspExp4'].isExpand =  true
                    tRefs.push('custNmRef1')
                  }
                }
                if( this.$bizUtil.isEmpty(this.apclamInfoSel_simple1.key)) { // 20240202
                  let bApcAplScValid7 = this.$refs['jDoneCrtn'].isValid()
                  tItem.push(bApcAplScValid7)
                  if(!bApcAplScValid7){
                    this.$refs['mspExp4'].isExpand =  true
                    tRefs.push('jDoneCrtn')
                  }
                }
             } 
          }
          
          // 지정대리청구인_2인
          if (this.isApcAsgnView2) {
             if ( this.simpAsgnClamAgntCd02 === '' ) {
                let bApcAplScValid8 = this.$refs['jTwoDa'].isValid()
                tItem.push(bApcAplScValid8)
                if(!bApcAplScValid8){
                  this.$refs['mspExp4'].isExpand =  true
                  tRefs.push('jTwoDa')
                }
            } else if ( this.simpAsgnClamAgntCd02 == 'A')  {
              if ( this.$bizUtil.isEmpty(this.apcInfo2.key)) { // 20240202
                let bApcAplScValid8 = this.$refs['jTwoDa'].isValid()
                tItem.push(bApcAplScValid8)
                if(!bApcAplScValid8){
                  this.$refs['mspExp4'].isExpand =  true
                  tRefs.push('jTwoDa')
                }
              }
              if ( this.$bizUtil.isEmpty(this.apclamInfoSel2.key)) {
                let bApcAplScValid9 = this.$refs['jTwoCrtn'].isValid()
                tItem.push(bApcAplScValid9)
                if(!bApcAplScValid9){
                  this.$refs['mspExp4'].isExpand =  true
                  tRefs.push('jTwoCrtn')
                }
              }
            } else if ( this.simpAsgnClamAgntCd02 == 'B')  { // 직접입력
              if( this.apcCustNm_simple2.trim() === '' ) {                
                let bApcAplScValid10 = this.$refs['custNmRef2'].isValid()
                tItem.push(bApcAplScValid10)
                if(!bApcAplScValid10){
                  this.$refs['mspExp4'].isExpand =  true
                  tRefs.push('custNmRef2')
                }
              }
              if (!this.$bizUtil.isCustNameValidation( this.apcCustNm_simple2.trim(), 'ZPER01')) {
                let bApcAplScValid10 = this.$refs['custNmRef2'].isValid()
                tItem.push(bApcAplScValid10)
                if(!bApcAplScValid10){
                  this.$refs['mspExp4'].isExpand =  true
                  tRefs.push('custNmRef2')
                }
              }
              if( this.$bizUtil.isEmpty(this.apclamInfoSel_simple2.key)) { // 20240202
                let bApcAplScValid11 = this.$refs['jDtwoCrtn'].isValid()
                tItem.push(bApcAplScValid11)
                if(!bApcAplScValid11){
                  this.$refs['mspExp4'].isExpand =  true
                  tRefs.push('jDtwoCrtn')
                }
              }
            } 
          }
        } // 지정대리청구인 지정가능여부 END

        
        
        // 친권자1,2 선택여부 체크
        if ( this.showPrtr1Row === true && (this.showPrtr2Row === true || (this.showPrtr2Row === false && this.singlePrtrCheckbox === true) ) ) {
          // 친권1
          if (this.prtr1ContrFmrtSel.key === '') {
            let bApcAplScValid13 = this.$refs['cOneCrtn'].isValid()
            tItem.push(bApcAplScValid13)
            if(!bApcAplScValid13){
              this.$refs['mspExp5'].isExpand =  true
              tRefs.push('cOneCrtn')
            }         
          } else if (this.$bizUtil.isEmpty(this.lv_Prtr1Sel.celno)) { // (2022.02.08_PJO)
            let bApcAplScValid14 = this.$refs['cOnePhone'].isValid()
            tItem.push(bApcAplScValid14)
            if(!bApcAplScValid14){
              this.$refs['mspExp5'].isExpand =  true
              tRefs.push('cOnePhone')
            } 
          }
          // 친권2
          if (!this.singlePrtrCheckbox && this.lv_Prtr2Sel.key === '' ) { //성명 추가
            let bApcAplScValid19 = this.$refs['cTwoNm'].isValid()
            tItem.push(bApcAplScValid19)
            if(!bApcAplScValid19){
              this.$refs['mspExp6'].isExpand =  true
              tRefs.push('cTwoNm')
            } 
          }
          if (!this.singlePrtrCheckbox && this.prtr2ContrFmrtSel.key === '') {
            let bApcAplScValid15 = this.$refs['cTwoCrtn'].isValid()
            tItem.push(bApcAplScValid15)
            if(!bApcAplScValid15){
              this.$refs['mspExp6'].isExpand =  true
              tRefs.push('cTwoCrtn')
            }       
          } else if (this.singlePrtrCheckbox === false) { // this.prtr2ContrFmrtSel.key !== '90') { // 미선택 , 조부무, 1인친권 이 아닌데..폰번호 없으면 // (2022.02.08_PJO)
            if (this.$bizUtil.isEmpty(this.lv_Prtr2Sel.celno)) {
              let bApcAplScValid16 = this.$refs['cTwoPhone'].isValid()
              tItem.push(bApcAplScValid16)
              if(!bApcAplScValid16){
                this.$refs['mspExp6'].isExpand =  true
                tRefs.push('cTwoPhone')
              }
            }
          }
          // 친권1, 친권2 관계 동일 검증
          if (!this.singlePrtrCheckbox && this.prtr1ContrFmrtSel.key === this.prtr2ContrFmrtSel.key) {
            let bApcAplScValid15 = this.$refs['cTwoCrtn'].isValid()
            tItem.push(bApcAplScValid15)
            if(!bApcAplScValid15){
              this.$refs['mspExp6'].isExpand =  true
              tRefs.push('cTwoCrtn')
            }
          }
        }
        ///////////////////////////////

        setTimeout(() => {
          let isPass = true
          if(isPass && tRefs.length > 0){               
            for(let i = 0; i < tRefs.length; i++){    
              if(tRefs[i] === 'pName'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }     
              if(tRefs[i] === 'yName'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              } 
              if(tRefs[i] === 'yContr'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              } 
              if(tRefs[i] === 'apcPrsonAplScSel'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              } 
              
              if(tRefs[i] === 'jOneDa'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
              if(tRefs[i] === 'jOneCrtn'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              } 
              if(tRefs[i] === 'custNmRef1'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              } 
              if(tRefs[i] === 'jDoneCrtn'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
              if(tRefs[i] === 'jTwoDa'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
              if(tRefs[i] === 'jTwoCrtn'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
              if(tRefs[i] === 'custNmRef2'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
              if(tRefs[i] === 'jDtwoCrtn'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
              if(tRefs[i] === 'cOneCrtn'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
              if(tRefs[i] === 'cOnePhone'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
              if(tRefs[i] === 'cTwoCrtn'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
              if(tRefs[i] === 'cTwoPhone'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
              if(tRefs[i] === 'cTwoNm'){
                document.getElementById('scroll_MSPPS410M').scrollTop = this.$refs[tRefs[i]].$el.offsetTop - 40 // 스크롤이동
                isPass = false
                rst = false
                break
              }
            }
          }
        }, 500)

        let sItem =  tItem.some((item) =>
        {      
        if ( !item )
          {          
            return true
          }
        })
        if(sItem) return

      return rst
    },
    // /******************************************************************************
    // * Function명 : fn_chkAsgnProxyClam
    // * 설명       : 지정대리청구인 checkbox
    // ******************************************************************************/
    // fn_chkAsgnProxyClam () {
    //   if (this.isApcAsgnView) {
    //     this.apcInfo = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
    //     this.apclamInfoSel = {key: '', label: ''}
    //   }
    // },
    /*********************************************************
     * Function명: fn_SetApc1
     * 설명: 지정대리청구인1 변경시 값 설정
     *********************************************************/
    fn_SetApc1 (param) {
       // ASR240200999_간편지대청 도입(김문준/240423)
      console.log('==> fn_SetApc1 [' + param.key + ']')
      if (param.key === undefined || param.key ==='' ) { // 20240402  ( 선택하세요 클릭시)
        this.isApcAsgnView_simple1 = false
        this.isApcAsgnView_default1 = true // 고객카드에 가족없는경우 세대조정 버튼 영역 보이기위해 true 설정함
        this.simpAsgnClamAgntCd01 = ''	// 지정대리인1_입력유형
        this.apcInfo = {
          key : '',
          label : '',
          name : '',
          chnlCustId : ''
        }
        this.apcCustNm_simple1 = ''
        this.apclamInfoSel_simple1.key = ''
      } else if (param.key === 'SIMPLE' ) {
        // ⑥ 선택하세요 → "1인지정"으로 디폴트(간편지정대리청구인 활성화와 동일 요건(치매급부 있는 계약에서만 적용)
        // ⑦ 지정대리청구인1 "간편지대청" 으로 디폴트
        this.isApcAsgnView_simple1 = true
        this.isApcAsgnView_default1 = false
        this.simpAsgnClamAgntCd01 = 'B'	// 지정대리인1_입력유형
        this.apcInfo = {celno: '', chnlCustId: '', isMcnstYn: '', key: 'SIMPLE', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        // this.apclamInfoSel = {key: '', label: ''} // 일반지대청_피보험자와의 관계
      } else {
        this.isApcAsgnView_simple1 = false
        this.isApcAsgnView_default1 = true
        this.simpAsgnClamAgntCd01 = 'A'	// 지정대리인1_입력유형
        this.apcInfo = {
          key : param.key,
          label : param.label,
          name : param.name,
          chnlCustId : param.chnlCustId
        }
        this.fn_ChkApc(param, 'A1')
      } 
    },
    /*********************************************************
     * Function명: fn_SetApc2
     * 설명: 지정대리청구인2 변경시 값 설정
     *********************************************************/
    fn_SetApc2 (param) {
      console.log('==> fn_SetApc2 [' + param.key + ']')
      // ASR240200999_간편지대청 도입(김문준/240423)
      if (param.key === undefined || param.key ==='' ) { // 20240402 ( 선택하세요 클릭시)
        this.isApcAsgnView_simple2 = false
        this.isApcAsgnView_default2 = true // 고객카드에 가족없는경우 세대조정 버튼 영역 보이기위해 true 설정함
        this.simpAsgnClamAgntCd02 = ''	// 지정대리인1_입력유형
        this.apcInfo2 = {
          key : '',
          label : '',
          name : '',
          chnlCustId : ''
        }
        this.apcCustNm_simple2 = ''
        this.apclamInfoSel_simple2.key = ''
      } else if (param.key === 'SIMPLE' ) {
        // this.fn_InitApcInfo('A2') // 기존 선택값 초기화
        this.isApcAsgnView_simple2 = true
        this.isApcAsgnView_default2 = false
        this.simpAsgnClamAgntCd02 = 'B' // 지정대리인2_입력유형

        //this.apcInfo2 = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.apclamInfoSel2 = {key: '', label: ''} // 일반지대청_피보험자와의 관계
        // ASR240200999_간편지대청 도입(김문준/240423)
        this.apcCustNm_simple2 = '' // 간편지대청_고객명
        this.apclamInfoSel_simple2= {key: '', label: ''} // 간편지대청_피보험자와의 관계
      } else {
        this.isApcAsgnView_simple2 = false
        this.isApcAsgnView_default2 = true
        this.simpAsgnClamAgntCd02 = 'A' // 지정대리인2_입력유형

        this.apcInfo2 = {
          key : param.key,
          label : param.label,
          name : param.name,
          chnlCustId : param.chnlCustId
        }
        this.fn_ChkApc(param, 'A2')
      }  
    },
    /*********************************************************
     * Function명: fn_ChkApc
     * 설명: 지정대리청구인 변경시 확인
     *********************************************************/
    fn_ChkApc (param, userType) {
      if (param.isMcnstYN === 'N') {
        // 필수컨설팅동의여부 가 N인 경우, 예금주 지정 불가
        //this.showAsgnProxyClamConfirm = true
        this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: false,
            title: '알림',
            content: '필수컨설팅 동의가 없는 고객은<br />지정대리청구인' + (userType === 'A2' ? '2' : '') + ' 지정이 불가능합니다.<br />고객등록동의 화면으로 이동하시겠습니까?',
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              this.fn_OnApcMcnstConfirmMovePositive(userType, 'N')

              this.$bottomModal.close(this.lv_AlertPop);
            },
            onPopupClose: () => {
              this.fn_InitApcInfo(userType)
              this.$bottomModal.close(this.lv_AlertPop);
            }
          }
        })
      }
      // (2021.05.31_PJO)
      if (this.isApcAsgnView2) {
         let v1 = this.apcInfo.chnlCustId === undefined ? '' : this.apcInfo.chnlCustId
          let v2 = this.apcInfo2.chnlCustId === undefined ? '' : this.apcInfo2.chnlCustId
          console.log(v1 + '/' + v2)
          if ( v1 !== '' && v2 !== '' && v1 === v2 ) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인 동일인 선택 불가')
            // this.fn_InitApcInfo('A2') // 240402
          }
      }
    },
    /******************************************************************************
    * Function명 : fn_ChkApcValidation
    * 설명       : 지정대리 청구인 유효값 체크 / (2024.04.23_PJO)
    ******************************************************************************/
    fn_ChkApcValidation () {
        let rst = true
        // ASR240200999_간편지대청 도입(김문준/240423)
        if (this.$bizUtil.isEmpty(this.apcPrsonAplScSel.key)) { // 240402
          this.getStore('confirm').dispatch('ADD', '[계약자] 정보를 펼쳐 지정대리청구인을 선택해 주세요!.') //문구수정(ASR231001107_231107_PJO)
          rst = false
          return false
        }
        // 계약자탭  show5 : false 열림 / true  닫힘
        if (!this.$refs['mspExp4'].isExpand && this.simpAsgnClamAgntCd01 == 'B' && ( this.$bizUtil.isEmpty(this.apcCustNm_simple1) || this.$bizUtil.isEmpty(this.apclamInfoSel_simple1.key))) { // 20240402
          this.getStore('confirm').dispatch('ADD', '[계약자] 정보를 펼쳐 지정대리청구인 정보를 입력하세요!.') //문구수정(ASR231001107_231107_PJO)
          rst = false
          return false
        }
      // 지정대리청구인_1인
      if (this.isApcAsgnView) {
        if ( this.simpAsgnClamAgntCd01 === '' ) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인이 선택되지 않았습니다.')
            return false
        } else if ( this.simpAsgnClamAgntCd01 == 'A')  { // 일반입력
          if ( this.$bizUtil.isEmpty(this.apcInfo.key)) { // 240402
            this.getStore('confirm').dispatch('ADD', '지정대리청구인이 선택되지 않았습니다.')
            return false
          }
          if (this.$bizUtil.isEmpty( this.apclamInfoSel.key)) { // 240402
            this.getStore('confirm').dispatch('ADD', '지정대리청구인 피보험자와의 관계가 선택되지 않았습니다.')
            return false
          }
        } else if ( this.simpAsgnClamAgntCd01 == 'B')  { // 직접입력
          if( this.apcCustNm_simple1.trim() === '' ) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인1 성명이 입력되지 않았습니다.')
            return false
          }
          if (!this.$bizUtil.isCustNameValidation(this.apcCustNm_simple1.trim(), 'ZPER01')) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인1 유효하지 않은 고객명입니다.(한글10자이내, 영문/숫자/특수문자/공백 불가)')
            return false
          }
          if( this.$bizUtil.isEmpty(this.apclamInfoSel_simple1.key)) { // 20240202
            this.getStore('confirm').dispatch('ADD', '지정대리청구인1 피보험자와의 관계가 선택되지 않았습니다.')
            return false
          }
        }
      }
      // 지정대리청구인_2인
      if (this.isApcAsgnView2) {
        if ( this.simpAsgnClamAgntCd02 === '' ) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인(2)이 선택되지 않았습니다.')
            return false
        } else if ( this.simpAsgnClamAgntCd02 == 'A')  {
          if ( this.$bizUtil.isEmpty(this.apcInfo2.key)) { // 20240202
            this.getStore('confirm').dispatch('ADD', '지정대리청구인(2)이 선택되지 않았습니다.')
            return
          }
          if ( this.$bizUtil.isEmpty(this.apclamInfoSel2.key)) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인(2) 피보험자와의 관계가 선택되지 않았습니다.')
            return
          }
        } else if ( this.simpAsgnClamAgntCd02 == 'B')  { // 직접입력
          if( this.apcCustNm_simple2.trim() === '' ) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인(2) 성명이 입력되지 않았습니다.')
            return false
          }
          if (!this.$bizUtil.isCustNameValidation( this.apcCustNm_simple2.trim(), 'ZPER01')) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인(2)유효하지 않은 고객명입니다.(한글10자이내, 영문/숫자/특수문자/공백 불가)')
            return false
          }
          if( this.$bizUtil.isEmpty(this.apclamInfoSel_simple2.key)) { // 20240202
            this.getStore('confirm').dispatch('ADD', '지정대리청구인 피보험자와의 관계(2)가 선택되지 않았습니다.')
            return false
          }
        }
      }
      return rst
    },
    /******************************************************************************
    * Function명 : fn_InitApcInfo
    * 설명       : 일반 - 지대청_고객정보, 피보험자와의 관계 , / 간편 - 간편지대청_고객명 ,피보험자와의 관계  -리셋  / (2021.05.31_PJO)
    ******************************************************************************/
    fn_InitApcInfo (param) {
      this.simpAsgnClamAgntCd01 = ''	// 지정대리인1_입력유형
      if (param === 'A1') {
        this.apcInfo = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.apclamInfoSel = {key: '', label: ''} // 일반지대청_피보험자와의 관계
        // ASR240200999_간편지대청 도입(김문준/240423)
        this.apcCustNm_simple1 = '' // 간편지대청_고객명
        this.apclamInfoSel_simple1= {key: '', label: ''} // 간편지대청_피보험자와의 관계
      } else if (param === 'A2') {
        this.apcInfo2 = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.apclamInfoSel2 = {key: '', label: ''} // 일반지대청_피보험자와의 관계
        // ASR240200999_간편지대청 도입(김문준/240423)
        this.apcCustNm_simple2 = '' // 간편지대청_고객명
        this.apclamInfoSel_simple2= {key: '', label: ''} // 간편지대청_피보험자와의 관계
      }
    },
    /******************************************************************************
    * Function명 : fn_OnApcMcnstConfirmMovePositive
    * 설명       : 지정대리청구인 > 필수컨설팅 동의 N > 지정대리청구인 이동 Y
    ************************* *****************************************************/
    fn_OnApcMcnstConfirmMovePositive (userType, isMcnstYN = null) {
      if (userType === 'A1') {
        // A:지정대리청구인
        if (this.apcInfo.chnlCustId.trim() !== '') {
          //this.$router.push({name: 'MSPCM001M', params: {chnlCustId: this.apcInfo.chnlCustId}})
          if (isMcnstYN === 'N') {
            this.$router.push({name: 'MSPCM050M', params: {custNm: this.apcInfo.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData, infoPrcusCnsntTypCd : '17'}})
          }
          else {
            this.$router.push({name: 'MSPCM050M', params: {custNm: this.apcInfo.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData}})
          }
        } else {
          this.getStore('confirm').dispatch('ADD', '지정대리청구인이 선택되지 않았습니다.')
        }
      } else if (userType === 'A2') {
        // A:지정대리청구인2
        if (this.apcInfo2.chnlCustId.trim() !== '') {
          //this.$router.push({name: 'MSPCM001M', params: {chnlCustId: this.apcInfo.chnlCustId}})
          if (isMcnstYN === 'N') {
            this.$router.push({name: 'MSPCM050M', params: {custNm: this.apcInfo2.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData, infoPrcusCnsntTypCd : '17'}})
          }
          else {
            this.$router.push({name: 'MSPCM050M', params: {custNm: this.apcInfo2.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData}})
          }
        } else {
          this.getStore('confirm').dispatch('ADD', '지정대리청구인2가 선택되지 않았습니다.')
        }
      }
    },

    /*********************************************************
     * Function명: fn_Init_ApcAsgn
     * 설명       : 지정대리청구인 > 초기세팅 / (2021.05.31_PJO)
     *********************************************************/
    fn_Init_ApcAsgn () {
      // this.isAsgnProxyClamPsb = this.lv_InputData.isAsgnProxyClamPsb
      // this.apcAsgnYn = 'Y'
      // 보험유형관리코드RD
      // N01 : 치매보험 - 2인까지 지정가능
      // 그외 : 1인 지정가능
      console.log('보험유형관리코드RD [' + this.zzInsrTypMgId + ']')
      if ( this.zzInsrTypMgId === 'N01' ) {
        // N01 : 치매보험 - 2인까지 지정가능
        this.apcInfoMsg = '피보험자의 「가족관계등록부상 또는 주민등록상의 배우자」 또는 「3촌이내 친족」만 지정가능'
        this.apcInfoMsg2 = '간편지정대리청구인으로 지정 시 계약자 外 지정대리청구인 성명 직접입력 바랍니다.(피보험자의 가족관계등록부상 또는 주민등록상의 배우자/아들/딸 만 지정가능)' // ASR240200999_간편지대청 도입(김문준/240423)
        this.apcPrsonAplScList = [{key: '01', label: '1인지정'}, {key: '02', label: '2인지정'}, {key: 'M1', label: '미지정(계약자요청)'}, {key: 'M2', label: '지정불가(약관상 자격 갖춘 자 없음)'}]
        this.apcPrsonAplScSel = {key: '', label: ''} // 기본값세팅 (미선택) / ASR220300068_보완 (2022.03.10 PJO)
      } else {
        // 그외 : 1인 지정가능
        this.apcInfoMsg = '가족관계증명서 또는 주민등록등본을 통하여 피보험자와 동거하거나 생계를 같이 하고 있는 배우자 또는 3촌이내 친족이 맞는지 확인 필요'
        this.apcPrsonAplScList = [{key: '01', label: '1인지정'}, {key: 'M1', label: '미지정(계약자요청)'}, {key: 'M2', label: '지정불가(약관상 자격 갖춘 자 없음)'}]
        this.apcPrsonAplScSel = {key: 'M1', label: '미지정(계약자요청)'} // 기본값세팅 미지정
      }
       // this.apcPrsonAplScCd = this.apcPrsonAplScSel.key // DB저장값 / ASR220300068_보완 (2022.03.10 PJO) // 사용안함 주석처리  / 20240423
    },
    /*********************************************************
     * Function명: fn_Init_ApcHouseholdMember
     * 설명       : N01일경우 가족카드목록 '선택하세요','간편지정대리청구인'추가  / (2024.04.23_PJO)
     *********************************************************/
    fn_Init_ApcHouseholdMember () {
       // N01일 경우 간편지대청-직접입력 추가
      if ( this.zzInsrTypMgId === 'N01' ) {
        this.householdMembers_apc.splice(0,0, // 첫번째 인덱스에 추가 // 20240402
          {
          key: '', // 순번
          label: '선택하세요', // 이름 4글자
          name: '', // 이름
          chnlCustId: '', // 채널고객ID
          knbEncr: '', // 주민등록번호 암호화 : YYMMDD1******
          knbEncr1: '', // 주민번호 앞자리
          knbEncr2: '', // 주민번호 뒷자리
          celno: '', // 연락처
          isMcnstYN: 'N', // 필수컨설팅동의여부
          style: true, // 스타일 , 필수컨설팅동의:N -> true
         },
        // )
        // this.householdMembers_apc.splice(1,0, // 두번째 인덱스에 추가 // // 20240402
        {
          key: 'SIMPLE', // 순번
          label: '간편지정대리청구인', // 이름 4글자
          name: '간편지정대리청구인', // 이름
          chnlCustId: '', // 채널고객ID
          knbEncr: '', // 주민등록번호 암호화 : YYMMDD1******
          knbEncr1: '', // 주민번호 앞자리
          knbEncr2: '', // 주민번호 뒷자리
          celno: '', // 연락처
          isMcnstYN: 'N', // 필수컨설팅동의여부
          style: true, // 스타일 , 필수컨설팅동의:N -> true
         }
        )
        // ASR240200999_간편지대청 도입(김문준/240423)
        // ⑥ 선택하세요 → "1인지정"으로 디폴트(간편지정대리청구인 활성화와 동일 요건(치매급부 있는 계약에서만 적용))
        // ⑦ 지정대리청구인1 "간편지정대리청구인" 으로 디폴트
        this.isApcAsgnView = true // 지대청1  활성화
        this.isApcAsgnView2 = false // 지대청2 비활성화
        this.apcPrsonAplScSel = {key: '01', label: '1인지정'}
        this.fn_SetApc1 ({key: 'SIMPLE', label: '간편지정대리청구인'} )
      } else {
        this.fn_SetApc1 ({key: '', label: ''} )
      }
    },
    /*********************************************************
     * Function명: fn_ApcPrsonAplScSel_OnClick
     * 설명       : 지정대리청구인 > 선택 / (2021.05.31_PJO)
     *********************************************************/
    fn_ApcPrsonAplScSel_OnClick (param) {
      let vKey = ''
      if ( param ) {
        vKey = param.key
        // 선택값 초기화
        this.fn_InitApcInfo('A1') // 피보험자와의 관계(일반,간편) ,간편지대청_고객명 -리셋 
        this.fn_InitApcInfo('A2')
        this.fn_SetApc1('')
        this.fn_SetApc2('')
        // this.apcPrsonAplScCd = vKey // 주석처리 // 20240423
        if (vKey === '01') { // 01 1인지정
          this.isApcAsgnView = true
          this.isApcAsgnView2 = false
          this.apcLblTxt1 = '지정대리청구인'
        } else if (vKey === '02') { // 02 2인지정
          this.isApcAsgnView = true
          this.isApcAsgnView2 = true
          this.apcLblTxt1 = '지정대리청구인1' // 20210720
          this.apcLblTxt2 = '지정대리청구인2'
        } else { // M1 미지정(계약자요청),M2 지정불가(약관상 자격 갖춘 자 없음)
          this.isApcAsgnView = false
          this.isApcAsgnView2 = false
          // ASR211000708_지정대리청구 로직 수정 및 신규 팝업 반영_사랑온 가입설계_20211109_youngki78.lee
          if ( this.zzInsrTypMgId === 'N01' ) { // 실제 가입설계한 주보험+특약의 보험유형관리코드RD = 'N01' 이면 지정대리인 2인 지정가능, 간편지대청가능
            if (vKey === 'M1' || vKey === 'M2') { // M1 미지정, M2 지정불가
              this.$refs.refApcAlertFlag.open() // ASR241100645_간편 지정대리청구인 넛지 (241203_PJO)
              // asis 주석처리
              //this.msgDesc = '지정대리청구인을 지정하지 않은 경우<br />보험금 청구시 고객 불편이 발생될 수 있습니다.<br />지정여부를 다시 확인하여 주시기 바랍니다.'
              //this.getStore('confirm').dispatch('ADD', this.msgDesc)
            }
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ChkPrtrContrFmrt
    * 설명       : 친권  관계 지정확인
    ******************************************************************************/
    fn_ChkPrtrContrFmrt (prtrNo) {
      if (!this.singlePrtrCheckbox && this.prtr1ContrFmrtSel.key === this.prtr2ContrFmrtSel.key) {
        // 친권1,2 관계 검증
        this.prtr2ContrFmrtSel = {key: '', label: ''}
        this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
        this.getStore('confirm').dispatch('ADD', '친권2는 친권1과 동일한 관계 지정이 불가능 합니다.')
      }

      if (prtrNo === '1') {
        if (this.prtr1ContrFmrtSel.key === '99') { // 99 기타(조부모 등)
          this.prtr1ContrFmrtSel = {key: '', label: ''}
          // this.getStore('confirm').dispatch('ADD', '계약자와 친권인이 아닌 경우 전자서명 불가합니다.\n\n모바일청약 혹은 지류청약 진행바랍니다.')
          //this.showPrtrMcnstConfirm2 = true
          this.fn_Confirm('계약자가 친권인이 아닌 경우 전자서명 불가합니다.<br />전자서명 종료 후 모바일 청약 혹은<br />지류청약으로 진행하시겠습니까?')
        }
      } else if (prtrNo === '2') {
        if (this.prtr2ContrFmrtSel.key === '90') { // 90: 1인친권자
          // confirm 문구 확인
          this.fn_IsOnePersonPrtrShow(this.onePersonPrtrShowMessage)
          // 친권2 성함 초기화
          // this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
          // this.singlePrtrCheckbox = true
          // this.fn_SinglePrtrCheckboxOnBoard()
        } else if (this.lv_Prtr2Sel.chnlCustId === '') {
          // 친권2 지정여부 확인
          this.prtr2ContrFmrtSel = {key: '', label: ''}
          this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
          this.getStore('confirm').dispatch('ADD', '친권2가 지정되지 않았습니다.')
        } else if (this.prtr2ContrFmrtSel.key === '99') { // 99 기타(조부모 등)
          this.prtr2ContrFmrtSel = {key: '', label: ''} // 친권2 관계 선택값 초기화
          this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
          this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '선택하세요', name: '', style: false} // 친권2 성함 초기화 (보완 / 22.02.08)
          // this.getStore('confirm').dispatch('ADD', '계약자와 친권인이 아닌 경우 전자서명 불가합니다.\n\n모바일청약 혹은 지류청약 진행바랍니다.')
          //this.showPrtrMcnstConfirm2 = true
          this.fn_Confirm('계약자가 친권인이 아닌 경우 전자서명 불가합니다.<br />전자서명 종료 후 모바일 청약 혹은<br />지류청약으로 진행하시겠습니까?')
        } else {
          // 친권인지정 confirm : 미성년자 계약으로 법적대리인으로 친권인이 지정되었음을 확인하였습니까?
          // this.isPrtrShowId = prtrNo
          // this.isPrtrShow = true
        }
      }
    },
    /*********************************************************
     * Function명: fn_ChkPrtr
     * 설명: 친권자 변경시 확인
     *********************************************************/
    fn_ChkPrtr (param) {
      this.lv_Prtr2Sel = {celno: param.celno, chnlCustId: param.chnlCustId, isMcnstYn: param.isMcnstYn, key: param.key, knbEncr: param.knbEncr, knbEncr1: param.knbEncr1, knbEncr2: param.knbEncr2, label: param.label, name: param.name, style: param.style}
      let emptyPrtrSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
      let emptyContrFmrtSel = {key: '', label: ''}
      let chk = true
      if (!this.$bizUtil.compareCurrentAge(param.knbEncr.substr(0, 6), this.standardAge)) {
        // 친권자 미성년자인 경우
        this.getStore('confirm').dispatch('ADD', '미성년자는 친권자 지정이 불가합니다.')
        chk = false
      // 1. 필수컨설팅동의여부 가 N인경우
      } else if (param.isMcnstYN === 'N') {
        this.prtrMcnstConfirmNo = '2' //친권자 필수컨설팅대상 No
        this.fn_ShowPrtrMcnstConfirm('필수컨설팅 동의가 없는 고객은<br />친권자 지정이 불가능합니다.<br />고객등록동의 화면으로 이동하시겠습니까?')
        chk = false
        return
      }

      //TO-DO AS-IS에서 prtrNo가 1로 넘어오는 경우가 없음 (확인필요)
      // if (prtrNo === '1') {
      //   if (param.chnlCustId === this.lv_Prtr2Sel.chnlCustId) {
      //     this.getStore('confirm').dispatch('ADD', '친권1은 친권2와 동일인 지정이 불가능 합니다.')
      //     chk = false
      //   }
      // } else if (prtrNo === '2') {
        // 친권2 변경시 관계2 초기화
        this.prtr2ContrFmrtSel = emptyContrFmrtSel
        this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
        if (param.chnlCustId === this.lv_Prtr1Sel.chnlCustId) {
          this.getStore('confirm').dispatch('ADD', '친권2는 친권1과 동일인 지정이 불가능 합니다.')
          chk = false
        }
      //}

      if (!chk) { // 유효성체크 false 이면 선택값 초기화
        //TO-DO AS-IS에서 prtrNo가 1로 넘어오는 경우가 없음 (확인필요)
        // if (prtrNo === '1') {
        //   this.lv_Prtr1Sel = emptyPrtrSel
        //   this.prtr1ContrFmrtSel = emptyContrFmrtSel
        // } else if (prtrNo === '2') {
          this.lv_Prtr2Sel = emptyPrtrSel
          this.prtr2ContrFmrtSel = emptyContrFmrtSel
          this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
        //}
        // return
      }
      console.log('fn_ChkPrtr_ true')
    },
    
    /******************************************************************************
    * Function명 : fn_OnPrtrMcnstConfirmMovePositive
    * 설명       : 친권자2 > 필수컨설팅 동의 N > 고객등록동의 화면으로 이동 Y
    *              친권자1은 계약자이므로 고객등록동의는 항상 완료된 상태임
    ******************************************************************************/
    fn_OnPrtrMcnstConfirmMovePositive () {
      if (this.lv_Prtr2Sel.chnlCustId.trim() !== '') {
        //this.$router.push({name: 'MSPCM001M', params: {chnlCustId: this.lv_Prtr2Sel.chnlCustId}})
        this.$router.push({name: 'MSPCM050M', params: {custNm: this.lv_Prtr2Sel.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData}})
      } else {
        this.getStore('confirm').dispatch('ADD', '친권2가 선택되지 않았습니다.')
      }
    },
    /*********************************************************
     * Function명: fn_OnPrtrMcnstConfirmMoveNegative
     * 설명       : 친권자 > 필수컨설팅 동의 N > 고객등록동의 화면으로 이동 N
     *********************************************************/
    fn_OnPrtrMcnstConfirmMoveNegative () {
      if (this.prtrMcnstConfirmNo === '1') {
        this.lv_Prtr1Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
      } else if (this.prtrMcnstConfirmNo === '2') {
        this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
      } else {
        this.getStore('confirm').dispatch('ADD', '친권자가 선택되지 않았습니다.')
      }
    },
    /******************************************************************************
    * Function명 : fn_GoToPSHome
    * 설명       : 설계청약 홈으로 이동
   ******************************************************************************/
    fn_GoToPSHome () {
      let vm = this
      let uuid = new Date().getTime()
      vm.$router.push({name: 'MSPPI310M', params: {uuid: uuid}})
    },
    /******************************************************************************
    * Function명 : fn_SinglePrtrCheckboxOnClick
    * 설명       : 1인친권자 체크박스 클릭
    ******************************************************************************/
    fn_SinglePrtrCheckboxOnClick () {
      if (this.singlePrtrCheckbox) {
        // confirm 문구 확인
        //this.isOnePersonPrtrShow = true        
        this.fn_IsOnePersonPrtrShow(this.onePersonPrtrShowMessage)
      } else {
        this.prtr2ContrFmrtSel = {key: '', label: ''}
        this.showPrtr2Row = true
        this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.componentKey += 1
      }
    },
    /******************************************************************************
    * Function명 : fn_IsOnePersonPrtrShow
    * 설명       : 1인친권자 확인
    ******************************************************************************/
    fn_IsOnePersonPrtrShow(contents) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: '알림',
          content: contents,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.singlePrtrCheckbox = true            
            this.fn_SinglePrtrCheckboxOnBoard()
            this.$bottomModal.close(this.lv_AlertPop);
          },
          onPopupClose: () => {
            this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
            if (this.prtr2ContrFmrtSel.key === '90') {
              this.prtr2ContrFmrtSel = {key: '', label: ''}
              this.componentKey += 1
            }
            this.$bottomModal.close(this.lv_AlertPop);
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_SinglePrtrCheckboxOnBoard
    * 설명       : 1인친권자 체크박스 후처리
    ******************************************************************************/
    fn_SinglePrtrCheckboxOnBoard () {
      if (this.singlePrtrCheckbox) {
        // this.prtr2ContrFmrtSel = {key: '90', label: '1인친권자'}        
        this.showPrtr2Row = false
        this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.componentKey += 1
      }
    },

    fn_Confirm(content) {
      const lv_ShowConfirm = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: content,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_ShowConfirm)
            this.fn_GoToPSHome()
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(lv_ShowConfirm)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_ShowConfirm)
          }
        }
      })
    },

    fn_ConfirmExit(content, srnId) {
      const lv_ShowConfirmExit = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: content,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_ShowConfirmExit)
            // 청약서발행으로 이동
            this.$MenuManager.fn_RouterPushSrnId(srnId)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(lv_ShowConfirmExit)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_ShowConfirmExit)
          }
        }
      })
    },

    /******************************************************************************
    * Function명 : fn_ShowPrtrMcnstConfirm
    * 설명       : 고객등록동의 이동 확인창
    ******************************************************************************/
    fn_ShowPrtrMcnstConfirm(content) {
      const lv_ShowAlert = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: content,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_ShowAlert)
            this.fn_OnPrtrMcnstConfirmMovePositive()
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(lv_ShowAlert)
            this.fn_OnPrtrMcnstConfirmMoveNegative()
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_ShowAlert)
          }
        }
      })
    },

    /******************************************************************************
    * Function명 : fn_TooltipOpen
    * 설명       : 1인친권자 툴팁 열기
    ******************************************************************************/
    fn_TooltipOpen() {
       this.$refs.nsbottomsheet.open()
    },
    /******************************************************************************
    * Function명 : fn_TooltipClose
    * 설명       : 1인친권자 툴팁 닫기
    ******************************************************************************/
    fn_TooltipClose() {
       this.$refs.nsbottomsheet.close()
    },
    /******************************************************************************
    * Function명 : fn_CheckCustNm
    * knbScCd (내/외국인여부) - 기본: 'ZPER01'(내국인)
    * 간편지대청 고객명 : *개인-내국인 고객명(간편지청 고객명) : 한글 10자 , 영문/숫자/특수문자/공란 불가 (간편BP채번시 고객명 체크규칙과 동일)
    ******************************************************************************/
    fn_CheckCustNm (param) { // 고객명 체크
       if ( param === '1')  {
        this.custNmErrorMsg = ''
        this.apcCustNm_simple1 = this.apcCustNm_simple1.trim()
        this.$refs.custNmRef1.clearValidation()
        if (this.$bizUtil.isEmpty(this.apcCustNm_simple1)) {
          this.custNmResult = false // 이름 유효성 체크 결과
          this.custNmErrorMsg = '필수입력항목입니다'
          this.$refs.custNmRef1.validate()
          return
        }
        if (this.$bizUtil.isCustNameValidation(this.apcCustNm_simple1, 'ZPER01')) {
          this.custNmResult = true // 이름 유효성 체크 결과
          this.custNmErrorMsg = ''
        } else {
          this.custNmResult = false // 이름 유효성 체크 결과
          this.custNmErrorMsg = '유효하지 않은 고객명입니다.(한글10자이내, 영문/숫자/특수문자/공백 불가)'
          this.$refs.custNmRef1.validate()
        }
       } else if ( param === '2')  {
        this.custNmErrorMsg2 = ''
        this.apcCustNm_simple2 = this.apcCustNm_simple2.trim()
        this.$refs.custNmRef2.clearValidation()

        if (this.$bizUtil.isEmpty(this.apcCustNm_simple2)) {
          this.custNmResult2 = false // 이름 유효성 체크 결과
          this.custNmErrorMsg2 = '필수입력항목입니다'
          this.$refs.custNmRef2.validate()
          return
        }
        if (this.$bizUtil.isCustNameValidation(this.apcCustNm_simple2, 'ZPER01')) {
          this.custNmResult2 = true // 이름 유효성 체크 결과
          this.custNmErrorMsg2 = ''
        } else {
          this.custNmResult2 = false // 이름 유효성 체크 결과
          this.custNmErrorMsg2 = '유효하지 않은 고객명(2)입니다.(한글10자이내, 영문/숫자/특수문자/공백 불가)'
          this.$refs.custNmRef2.validate()
        }
       }
      return
    },
    /******************************************************************************
    * 초회납입방법 신용카드납 on/off 체크 (ASR240400333_24.05 장한나)
      CARD_YN_CHK : Y / N  -  업무컨피그(초회보험료 신용카드)=Y &&  보험료납입유형값RD=C 인경우 가능
    ******************************************************************************/
    fn_CardYN () {
      // 로컬강제테스트
      // this.lv_InputData.zzPrmPadTVId = 'C'

      let lv_Vm = this
      let btnId = 'S'
      let CARD_YN = ''
      lv_Vm.CARD_YN_CHK = 'N'
      let pParams = {}
      pParams = {envId: 'CARD_YN',sno: '1'} // 초회보험료 신용카드 가능여부
      this.post(lv_Vm, pParams, 'txTSSAD02S4', btnId)
        .then(function (response) {
        if (response.body !== null) {
           let returnVo = response.body
           if( !lv_Vm.$bizUtil.isEmpty(returnVo.envCntnt)) {// 값 ENV_CNTNT
            CARD_YN = returnVo.envCntnt
            if ( !lv_Vm.$bizUtil.isEmpty( lv_Vm.lv_InputData.zzPrmPadTVId ) &&  (lv_Vm.lv_InputData.zzPrmPadTVId === 'C' && CARD_YN === 'Y') ) {
              lv_Vm.CARD_YN_CHK = 'Y'
            }
          }
          console.log('CARD_YN:' + CARD_YN)
          console.log('CARD_YN_CHK:' + lv_Vm.CARD_YN_CHK)
        }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * 미지정/지정불가 선택
    * 디폴트값: 지정대리인
    * ASR241100645_간편 지정대리청구인 넛지 (241203_PJO) 
    ******************************************************************************/
    fn_ApcAlert1(){
      // console.log('디폴트값: 미지정/지정불가 선택')
      this.$refs.refApcAlertFlag.close()
      // 미지정/지정불가 시만 여기 로 들어옴 , 디폴트값 변경없습
    },
    /******************************************************************************
    * 지정 선택
    * 디폴트값: 1인지정,간편지정대리청구인
    * ASR241100645_간편 지정대리청구인 넛지 (241203_PJO)
    ******************************************************************************/
    fn_ApcAlert2(){
      // console.log('디폴트값: 1인지정,간편지정대리청구인')
      // 지정대리청구 값 초기화
      this.fn_Init_ApcHouseholdMember()
      this.$refs.refApcAlertFlag.close()
    },
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>